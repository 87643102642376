import { ApolloClient } from '@apollo/client';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, uniqBy } from 'lodash';

import { SearchItemTypes } from 'constants/search.constants';
import {
    buildCspAvailabilitySearchParams,
    buildCspLeaseSearchParams,
    buildCspPropertySearchParams,
    buildCspSaleSearchParams,
    findMatchedAndUnmatchedOsmIds,
} from 'helpers/searchHelper';
import { RootState } from 'store';
import { AvailabilitySearchParametersInput } from 'types/CspInputs';
import { PropertyResultItem, SearchPropertiesResult } from 'types/Search/PropertySearchResultProps';
import {
    AvailabilityFilters,
    defaultAvailabilityFilters,
    defaultLeaseFilters,
    defaultPropertyFilters,
    defaultSalesFilters,
    LeaseFilters,
    PropertyFilter,
    PropertyFilterFields,
    SalesFilters,
} from 'types/Search/SearchFilters';
import {
    GET_AVAILABILITIES_FLOORS,
    GET_AVAILABILITY_STATS,
    SEARCH_AVAILABILITIES,
    SEARCH_AVAILABILITIES_IDS,
    SEARCH_LEASE,
    SEARCH_PROPERTIES_IDS,
    SEARCH_SALES,
} from '../services/graphql/csp';
import { selectMarketSphereOsmMapping } from './marketSphereOsmMappingSlice';
import { clearPresentation, selectSourceSystem, selectSourceSystemType } from './presentationSlice';

interface SearchSliceState {
    availabilityFloors?: string[];
    availabilityFilters: AvailabilityFilters;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    availabilityStats?: any;
    propertyFilters: PropertyFilterFields;
    isSearchToPresentationModalOpen?: boolean;
    showResults: boolean;
    showSearchPanel: boolean;
    searchType: SearchItemTypes;
    searchResults: SearchPropertiesResult;
    missingProperties: PropertyResultItem[];
    selectedResultItem?: PropertyResultItem;
    searchParams: {
        bronzeSourceSystem: string;
        input: string;
    };
    salesFilters: SalesFilters;
    leaseFilters: LeaseFilters;
    searchFilters: SearchFilters;
    allPropertiesSearchResults: PropertyResultItem[];
}

interface SearchFilters {
    [key: string]: string | string[];
}

interface SearchQueryProps {
    client: ApolloClient<object>;
    bronzeSourceSystem?: string | null;
}

interface AvailabilitySearchQueryProps extends SearchQueryProps {
    input: AvailabilitySearchParametersInput;
}

const defaultSearchResults = {
    properties: [],
    stats: {
        totalProperties: 0,
        geoBoundingBox: {
            bottomRight: {
                lat: 0,
                long: 0,
            },
            topLeft: {
                lat: 0,
                long: 0,
            },
        },
    },
};
const initialState: SearchSliceState = {
    searchType: '',
    showResults: false,
    showSearchPanel: false,
    availabilityFilters: defaultAvailabilityFilters,
    propertyFilters: defaultPropertyFilters,
    availabilityFloors: [],
    searchResults: defaultSearchResults,
    missingProperties: [],
    searchParams: {
        bronzeSourceSystem: '',
        input: '',
    },
    salesFilters: defaultSalesFilters,
    leaseFilters: defaultLeaseFilters,
    searchFilters: {},
    allPropertiesSearchResults: [],
};

export const fetchAvailabilityFilters = createAsyncThunk(
    'properties/fetchAvailabilityFilters',
    async ({ client, bronzeSourceSystem, input }: AvailabilitySearchQueryProps) => {
        const result = await client.query({
            query: GET_AVAILABILITY_STATS,
            context: { clientName: 'availabilityServiceEndpoint' },
            variables: { bronzeSourceSystem, input },
        });
        return result.data.searchAvailabilities;
    }
);

let availabilities = [] as string[];

const fetchFloors = async ({ client, bronzeSourceSystem, input }: AvailabilitySearchQueryProps) => {
    const result = await client.query({
        query: GET_AVAILABILITIES_FLOORS,
        context: { clientName: 'availabilityServiceEndpoint' },
        variables: { bronzeSourceSystem, input },
    });
    const output = result.data.searchAvailabilities;
    if (!output || !output.availabilities) return;

    const totalAvailabilities = output.stats?.totalAvailabilities;

    if (output.availabilities?.length > 0) {
        const floors = output.availabilities.map((availability: unknown) => {
            return `${get(availability, 'property.id')}_${get(availability, 'allFloors')}`;
        });
        availabilities = availabilities.concat(floors);
    }
    if (totalAvailabilities > availabilities.length && input.pageNumber) {
        input.pageNumber = input.pageNumber + 1;
        await fetchFloors({ client, bronzeSourceSystem, input });
    }
    return availabilities;
};

export const fetchAvailabilitiesFloors = createAsyncThunk(
    'properties/fetchAvailabilitiesFloors',
    async ({ client, bronzeSourceSystem, input }: AvailabilitySearchQueryProps) => {
        availabilities = [] as string[];
        const allFloors = await fetchFloors({ client, bronzeSourceSystem, input });
        return allFloors;
    }
);

export const searchAvailabilities = createAsyncThunk(
    'properties/searchAvailabilities',
    async ({ client }: { client: ApolloClient<object> }, { getState }) => {
        const state = getState() as RootState;
        const availabilityFilters = selectAvailabilitiesFilters(state);
        const sourceSystem = selectSourceSystem(state);
        const sourceSystemType = selectSourceSystemType(state);
        const result = await client.query({
            query: SEARCH_AVAILABILITIES,
            context: { clientName: 'availabilityServiceEndpoint' },
            variables: {
                [sourceSystemType]: sourceSystem,
                input: buildCspAvailabilitySearchParams(-1, 1, availabilityFilters),
            },
        });
        return result.data.searchAvailabilities;
    }
);

export const searchSales = createAsyncThunk(
    'properties/searchSales',
    async ({ client }: { client: ApolloClient<object> }, { getState }) => {
        const state = getState() as RootState;
        const salesFilters = selectSalesFilters(state);
        const sourceSystem = selectSourceSystem(state);
        const result = await client.query({
            query: SEARCH_SALES,
            context: { clientName: 'salesServiceEndpoint' },
            variables: {
                silverSourceSystem: sourceSystem,
                input: buildCspSaleSearchParams(-1, 1, salesFilters),
            },
        });

        return result.data.searchSalesTransaction;
    }
);

export const searchLease = createAsyncThunk(
    'properties/searchLease',
    async ({ client }: { client: ApolloClient<object> }, { getState }) => {
        const state = getState() as RootState;
        const leaseFilters = selectLeaseFilters(state);
        const sourceSystem = selectSourceSystem(state);
        const result = await client.query({
            query: SEARCH_LEASE,
            context: { clientName: 'leaseServiceEndpoint' },
            variables: {
                silverSourceSystem: sourceSystem,
                input: buildCspLeaseSearchParams(-1, 1, leaseFilters),
            },
        });
        return result.data.searchLeases;
    }
);

export const searchAvailabilitiesIds = createAsyncThunk(
    'properties/searchAvailabilities',
    async ({ client, bronzeSourceSystem, input }: AvailabilitySearchQueryProps) => {
        const result = await client.query({
            query: SEARCH_AVAILABILITIES_IDS,
            context: { clientName: 'availabilityServiceEndpoint' },
            variables: { bronzeSourceSystem, input },
        });
        return result.data.searchAvailabilities;
    }
);

export const searchProperties = createAsyncThunk(
    'properties/searchProperties',
    async ({ client }: { client: ApolloClient<object> }, { getState }) => {
        const state = getState() as RootState;
        const propertyFilters = selectPropertyFilters(state);
        const sourceSystem = selectSourceSystem(state);
        const sourceSystemType = selectSourceSystemType(state);

        const result = await client.query({
            query: SEARCH_PROPERTIES_IDS,
            context: { clientName: 'propertyServiceEndpoint' },
            variables: {
                [sourceSystemType]: sourceSystem,
                input: buildCspPropertySearchParams(-1, 1, propertyFilters),
            },
        });

        return result.data.searchProperties;
    }
);

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setMissingProperties(state, action: PayloadAction<PropertyResultItem[]>) {
            const combined = [...state.missingProperties, ...action.payload];
            state.missingProperties = uniqBy(combined, 'id');
        },
        setAvailabilitiesFloors(state, action: PayloadAction<string[]>) {
            state.availabilityFloors = action.payload;
        },
        updateAvailabilityFilters(state, action: PayloadAction<AvailabilityFilters>) {
            state.availabilityFilters = action.payload;
        },
        assignAvailabilityFilters(state, action: PayloadAction<Partial<AvailabilityFilters>>) {
            Object.assign(state.availabilityFilters, action.payload);
        },
        assignAvailabilityPropertyFilters(
            state,
            action: PayloadAction<Partial<PropertyFilter['values'][number]>>
        ) {
            if (state.availabilityFilters.properties == null) {
                state.availabilityFilters.properties = { values: [{}] };
            }
            if (state.availabilityFilters.properties.values.length === 0) {
                state.availabilityFilters.properties.values.push({});
            }
            Object.assign(state.availabilityFilters.properties.values[0], action.payload);
        },
        setAvailabilityStats(state, action) {
            state.availabilityStats = action.payload;
        },
        updatePropertyFilters(state, action: PayloadAction<PropertyFilterFields>) {
            state.propertyFilters = action.payload;
        },
        setSearchToPresentationModalOpen(state, action) {
            state.isSearchToPresentationModalOpen = action.payload;
        },
        setSearchType(state, action: PayloadAction<SearchItemTypes>) {
            state.searchType = action.payload;
        },
        setSearchParams(state, action) {
            state.searchParams = action.payload;
            const { bronzeSourceSystem, input } = action.payload;
            state.searchParams.bronzeSourceSystem = bronzeSourceSystem;
            state.searchParams.input = input;
        },
        setShowResults(state, action: PayloadAction<boolean>) {
            state.showResults = action.payload;
        },
        setShowSearchPanel(state, action: PayloadAction<boolean>) {
            state.showSearchPanel = action.payload;
        },
        setSearchResults(state, action: PayloadAction<SearchPropertiesResult>) {
            state.searchResults = action.payload;
        },
        setSelectedResultItem(state, action: PayloadAction<PropertyResultItem | undefined>) {
            state.selectedResultItem = action.payload;
        },
        setDefaultValues(
            state,
            action: PayloadAction<{ showRefreshedSearchPanel: boolean; searchType?: string }>
        ) {
            Object.assign(state, {
                ...initialState,
                showRefreshedSearchPanel: action.payload.showRefreshedSearchPanel,
                searchType: action.payload.searchType,
                missingProperties: state.missingProperties,
            });
            state.searchResults = defaultSearchResults;
        },
        updateSalesFilters(state, action: PayloadAction<SalesFilters>) {
            state.salesFilters = action.payload;
        },
        updateLeaseFilters(state, action: PayloadAction<LeaseFilters>) {
            state.leaseFilters = action.payload;
        },
        setSearchFilters(state, action: PayloadAction<SearchFilters>) {
            state.searchFilters = action.payload;
        },
        assignSearchFilters(state, action: PayloadAction<Partial<SearchFilters>>) {
            Object.assign(state.searchFilters, action.payload);
        },
        setAllPropertiesSearchResults(state, action: PayloadAction<PropertyResultItem[]>) {
            state.allPropertiesSearchResults = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
        builder.addCase(searchProperties.fulfilled, (state, action) => {
            state.searchResults = action.payload;
        });
    },
});

export const selectAvailabilitiesFloors = (state: RootState): string[] | undefined => {
    return state.search.availabilityFloors;
};

export const selectAvailabilitiesFilters = (state: RootState): AvailabilityFilters => {
    return state.search.availabilityFilters;
};

export const selectSalesFilters = (state: RootState): SalesFilters => {
    return state.search.salesFilters;
};

export const selectLeaseFilters = (state: RootState): LeaseFilters => {
    return state.search.leaseFilters;
};

export const selectAvailabilityStats = (state: RootState) => {
    return state.search.availabilityStats;
};

export const selectPropertyFilters = (state: RootState): PropertyFilterFields => {
    return state.search.propertyFilters;
};

export const selectSearchToPresentationModal = (state: RootState) => {
    return state.search.isSearchToPresentationModalOpen;
};

export const selectSearchType = (state: RootState) => {
    return state.search.searchType;
};

export const selectSearchParams = (state: RootState) => {
    return state.search.searchParams;
};

export const selectShowResults = (state: RootState) => {
    return state.search.showResults;
};

export const selectShowSearchPanel = (state: RootState) => {
    return state.search.showSearchPanel;
};

export const selectSearchResults = (state: RootState) => {
    return state.search.searchResults;
};

export const isSearchResultsEmpty = (state: RootState) => {
    return state.search.searchResults?.properties?.length > 0;
};

export const selectAllPropertiesSearchResults = (state: RootState) => {
    return state.search.allPropertiesSearchResults;
};

export const selectOsmGroupsSearchResults = createSelector(
    [selectAllPropertiesSearchResults, selectMarketSphereOsmMapping],
    (properties, mappingsOsmLookup) => findMatchedAndUnmatchedOsmIds(properties, mappingsOsmLookup)
);

export const selectResultItem = (state: RootState) => {
    return state.search.selectedResultItem;
};

export const selectMissingProperties = (state: RootState) => {
    return state.search.missingProperties;
};

export const selectSearchFilters = (state: RootState) => {
    return state.search.searchFilters;
};

export const {
    setMissingProperties,
    setAvailabilitiesFloors,
    setAvailabilityStats,
    updateAvailabilityFilters,
    assignAvailabilityFilters,
    assignAvailabilityPropertyFilters,
    updatePropertyFilters,
    setSearchToPresentationModalOpen,
    setSearchType,
    setSearchParams,
    setShowResults,
    setShowSearchPanel,
    setSearchResults,
    setSelectedResultItem,
    setDefaultValues,
    updateSalesFilters,
    updateLeaseFilters,
    setSearchFilters,
    assignSearchFilters,
    setAllPropertiesSearchResults,
} = searchSlice.actions;

export default searchSlice.reducer;
