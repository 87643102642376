interface NumberFormatOptions extends Intl.NumberFormatOptions {
    trailingZeroDisplay: 'auto' | 'stripIfInteger';
}

const DOLLAR_FORMATTER = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    trailingZeroDisplay: 'stripIfInteger',
} as NumberFormatOptions);

export const pluralize = function (count: number, text: string, includeCount: boolean) {
    includeCount = typeof includeCount === 'undefined' ? false : includeCount;
    let output = `${includeCount ? count.toLocaleString() : ''} `;
    if (count === 1) {
        output += text;
    }
    // This isn't perfect. If we need something fancier we could always use the pluralize library.
    else if (text[text.length - 1] === 'y') {
        text = text.slice(0, text.length - 1);
        output += text + 'ies';
    } else {
        output += text + 's';
    }

    return output.trim();
};

export const ordinalize = (value: number) => {
    let ord = 'th';
    if (value % 10 == 1 && value % 100 != 11) {
        ord = 'st';
    } else if (value % 10 == 2 && value % 100 != 12) {
        ord = 'nd';
    } else if (value % 10 == 3 && value % 100 != 13) {
        ord = 'rd';
    }
    return ord;
};

export const isStringValidPath = (path: string) => {
    // Regular expression to match common file path patterns
    const pathRegex = /(?:\/|[A-Za-z]:\\|\\\\|\.\/|\.\.\/)/;

    return pathRegex.test(path);
};

export const toDollarString = (value: number) => {
    return DOLLAR_FORMATTER.format(value);
};
