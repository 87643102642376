import { PropertyStatus } from 'types/CspInputs';

export const SEARCH_BUTTON = 'Search';
export const MORE_FILTERS = 'More Filters';
export type SearchItemTypes = 'Properties' | 'Availabilities' | 'Lease Comps' | 'Sales' | '';
export const SEARCH_ITEMS = [
    {
        value: 'Properties',
        label: 'Properties',
    },
    {
        value: 'Availabilities',
        label: 'Availabilities',
    },
    {
        value: 'Lease Comps',
        label: 'Lease Comps',
    },
    {
        value: 'Sales',
        label: 'Sales',
    },
];

export const industries = [
    'Aerospace & Defense',
    'Construction, Machinery & Materials',
    'Consumer Products',
    'Consumer Services',
    'Education',
    'Energy & Utilities',
    'Finance',
    'Food & Beverage',
    'Government',
    'Health',
    'Logistics & Distribution',
    'Media',
    'Nonprofit',
    'Professional and Business Services',
    'Retail',
    'Technology',
    'Travel & Recreation',
];

export const buildingStatusList: PropertyStatus[] = [
    'Existing',
    'Under Renovation',
    'Under Construction',
    'Proposed Short-Term',
    'Proposed Long-Term',
];

export const propertyStatusList = ['Office', 'Lab', 'Flex R&D', 'Industrial'];

export const buildingClassAllowed = ['Class A', 'Class B', 'Class C', 'Trophy'];

export const checkboxOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];
