import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import type { LoadStatus } from 'types/LoadStatus';
import endpoints from 'utils/apiClient/endpoints';
import { EXCLUDED_LAYERS } from '../helpers/buildingsExcludeHelper';
import { BuildingExcludeSettings, LayerExcludedBuildings } from '../types/BuildingsExcludeSettings';

export interface ExcludedResetBuilding {
    buildingId: number;
    index: number;
}

export interface BuildingsExcludeSettingsSliceState {
    settings: LayerExcludedBuildings[];
    status?: LoadStatus;
}

const initialState: BuildingsExcludeSettingsSliceState = {
    settings: [],
    status: 'idle',
};

export const fetchSettings = createAsyncThunk(
    'buildingExcludedSettings/fetchMappings',
    async () => {
        const response = await endpoints.buildingExcludedSettings.settings.get();
        return (await response.json()) as BuildingExcludeSettings[];
    }
);

export const saveExcludedKeys = createAsyncThunk(
    'buildingExcludedSettings/saveMapping',
    async (settings: LayerExcludedBuildings) => {
        const response = await endpoints.buildingExcludedSettings.save.post({
            templateValues: {
                layer: settings.layer,
            },
            fetchOptions: {
                body: JSON.stringify(settings),
            },
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return settings;
    }
);

export const fetchExcludedKeys = createAsyncThunk(
    'buildingExcludedSettings/filter',
    async (layer: string) => {
        const response = await endpoints.buildingExcludedSettings.filter.get({
            templateValues: {
                layer: layer,
            },
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return await response.json();
    }
);

function convertSettingsToExcludedBuildings(
    settings: BuildingExcludeSettings[]
): LayerExcludedBuildings[] {
    const excludedBuildings: LayerExcludedBuildings[] = [];

    for (const setting of settings) {
        const existingLayer = excludedBuildings.find(
            (excludedBuilding) => excludedBuilding.layer === setting.layer
        );

        if (existingLayer) {
            existingLayer.buildingIds.push(setting.buildingId);
        } else {
            excludedBuildings.push({
                buildingIds: [setting.buildingId],
                layer: setting.layer as EXCLUDED_LAYERS,
            });
        }
    }

    return excludedBuildings;
}

export const setAndSaveBuildingsExclusion = createAsyncThunk(
    'buildingExcludedSettings/setAndSave',
    async (payload: LayerExcludedBuildings, { getState, dispatch }) => {
        dispatch(setLayerExcludedBuildingIds(payload));
        const state = getState() as RootState;
        const layerSettings = state.buildingsExcludeSettings.settings.find(
            (item) => item.layer == payload.layer
        );
        if (layerSettings) {
            await dispatch(saveExcludedKeys(layerSettings));
        }
    }
);

export const buildingsExcludeSettingsSlice = createSlice({
    name: 'osmAdminSettings',
    initialState,
    reducers: {
        setSettings(state, action: PayloadAction<BuildingExcludeSettings[]>) {
            const data = action.payload;
            state.settings = convertSettingsToExcludedBuildings(data);
        },
        setLayerExcludedBuildingIds(state, action: PayloadAction<LayerExcludedBuildings>) {
            const payload = action.payload;
            const layer = state.settings.find((item) => item.layer == payload.layer);
            if (layer) {
                layer.buildingIds = payload.buildingIds;
            } else {
                state.settings.push(payload);
            }
        },
        addBuildingIdToExcludedLayer(state, action: PayloadAction<LayerExcludedBuildings>) {
            const payload = action.payload;
            const layer = state.settings.find((item) => item.layer == payload.layer);
            if (!layer) state.settings.push(payload);
            else {
                layer.buildingIds = [...payload.buildingIds, ...layer.buildingIds];
            }
        },
        removeBuildingIdFromExcludedLayer(state, action: PayloadAction<LayerExcludedBuildings>) {
            const payload = action.payload;
            const layer = state.settings.find((item) => item.layer == payload.layer);
            if (layer) {
                layer.buildingIds = layer.buildingIds.filter(
                    (buildingId) => !payload.buildingIds.includes(buildingId)
                );
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSettings.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchSettings.fulfilled, (state, action) => {
                state.status = 'ready';
                state.settings = convertSettingsToExcludedBuildings(
                    action.payload as BuildingExcludeSettings[]
                );
            });
    },
});

const makeSelectBuildingsByLayer = (layer: string) => {
    return createSelector(
        (state: RootState) => state.buildingsExcludeSettings.settings,
        (settings): number[] => {
            if (!settings) return [];
            return settings
                .filter((item) => item.layer === layer)
                .flatMap((item) => item.buildingIds);
        }
    );
};

export const selectOsmAdminExcludedKeys = makeSelectBuildingsByLayer('OSM');

export const selectDevPipelineAdminExcludedKeys = makeSelectBuildingsByLayer('DevPipeline');

export const {
    setLayerExcludedBuildingIds,
    addBuildingIdToExcludedLayer,
    removeBuildingIdFromExcludedLayer,
    setSettings,
} = buildingsExcludeSettingsSlice.actions;

export default buildingsExcludeSettingsSlice.reducer;
