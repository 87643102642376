import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Layer from '@arcgis/core/layers/Layer';
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import PointSymbol3D from '@arcgis/core/symbols/PointSymbol3D';
import { chain, get, isString, uniq } from 'lodash';

import configuration from 'constants/amenities.constants';
import AmenityLayerMetadata, { CenterLatLng } from 'types/Layers/AmenityLayerMetadata';
import { LibraryAmenitiesSaveState } from 'types/Layers/LibraryItemSaveState';
import LibraryLayerTreeItem, { AmenityTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import { retryOn429Response } from 'utils/apiClient/apiUtil';
import endpoints from 'utils/apiClient/endpoints';
import { getOppositeShade, hexToRgb } from 'utils/colorUtils';
import { buildIcon } from 'utils/iconBuilder/iconBuilderUtils';
import { nanoid } from 'utils/idUtils';

const iconPath = window.location.origin + '/src/res/img/icons/amenities/';
const defaultIconColor = '#AAAAAA';
const iconCache = {} as Record<string, string>;

//in pixels
const iconSize = {
    x: 64,
    y: 64,
};
const pt = 1.33; //W3C pt

const pixelToPoint = (pixels: number) => {
    return Number(pixels / pt);
};

const getIconByCategory = (category: string) => {
    const defaultIcon = 'generic.svg';
    if (!category) return defaultIcon;
    const iconsByCategory = configuration.iconsByCategory;
    const icon = iconsByCategory[category];

    if (icon) return iconPath + icon.icon + '.svg';
    else return iconPath + defaultIcon;
};

const createAmenityIcon = (category: string, backgroundColor: string) => {
    const iconBackgroundUrl = 'src/res/img/icons/amenities/background.svg';
    const foregroundColor = getOppositeShade(hexToRgb(backgroundColor), 200);
    return buildIcon({
        images: [
            {
                url: iconBackgroundUrl,
                color: backgroundColor,
                stroke: '#FFFFFF',
            },
            {
                url: getIconByCategory(category),
                color: foregroundColor,
                scale: 0.5,
            },
        ],
        size: iconSize,
        shadow: false,
    });
};

const getCategoryColor = (category: string) => {
    const colorSettings = configuration.colors[category];
    return colorSettings ? colorSettings.color : defaultIconColor;
};

const buildIcons = async (categories: string[], backgroundColor: string) => {
    await Promise.all(
        categories.map(async (category) => {
            if (!iconCache[category]) {
                const canvas = await createAmenityIcon(category, backgroundColor);
                iconCache[category] = canvas.toDataURL();
            }
        })
    );
};

const createPointRenderer = (categories: Array<string>) => {
    const size = pixelToPoint(iconSize.x); //in points
    const uniqueValueInfos = categories.map((category: string) => {
        const href = iconCache[category];
        return new UniqueValueInfo({
            value: category,
            symbol: new PointSymbol3D({
                symbolLayers: [
                    {
                        type: 'icon',
                        resource: {
                            href: href,
                        },
                        size: size,
                    },
                ],
                verticalOffset: {
                    screenLength: 40,
                    maxWorldLength: 100,
                    minWorldLength: 20,
                },
                callout: {
                    type: 'line',
                    size: 1.5,
                    color: 'white',
                },
            }),
        });
    });

    return new UniqueValueRenderer({
        field: 'Category',
        uniqueValueInfos: uniqueValueInfos,
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const getCategories = (businesses: any) => {
    const categories = [];
    for (const business of businesses) {
        for (const category of business.categories) {
            categories.push(category.alias);
        }
    }
    return uniq(categories);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const createGraphics = (businesses: any) => {
    const graphics = [];
    for (const business of businesses) {
        const coordinates = business.coordinates;
        const point = new Point({
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
        });
        graphics.push(
            new Graphic({
                geometry: point,
                attributes: {
                    Id: business.id,
                    Category: business.categories[0].alias,
                },
            })
        );
    }
    return graphics;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const createAmenitiesLayer = async (key: string, businesses: any, alias: string) => {
    const categories = getCategories(businesses);
    const backgroundColor = getCategoryColor(alias);
    await buildIcons(categories, backgroundColor);

    const renderer = createPointRenderer(categories);
    const graphics = createGraphics(businesses);

    return new FeatureLayer({
        id: key,
        source: graphics,
        objectIdField: 'OBJECTID',
        fields: [
            {
                name: 'OBJECTID',
                type: 'oid',
            },
            {
                name: 'Category',
                type: 'string',
            },
        ],
        renderer: renderer,
        legendEnabled: false,
        elevationInfo: {
            mode: 'relative-to-scene',
        },
    });
};

interface YelpSearchParams {
    term?: string;
    location?: string;
    categories?: string;
    offset?: number;
    limit?: number;
}

interface YelpSearchResult {
    total: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    businesses: any[];
}

const LIMIT = 500;
const MAX_ITEMS_PER_REQUEST = 50;

const search = retryOn429Response(async (params: YelpSearchParams): Promise<YelpSearchResult> => {
    const result = await endpoints.yelpFusion.search.get({
        queryParameters: params as Record<string, string | number | boolean>,
    });
    return await result.json();
});

export const queueFilterSearch = async (
    params: YelpSearchParams,
    count = 0,
    offset = 0
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
): Promise<any[]> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    const businesses: any[] = [];
    let currentOffset = offset;

    while (currentOffset < LIMIT && count < 200) {
        const response = await search({
            ...params,
            offset: currentOffset,
            limit: MAX_ITEMS_PER_REQUEST,
        });

        if (!response || response.businesses.length === 0) {
            break;
        }

        count += response.businesses.length;
        businesses.push(...response.businesses);

        currentOffset += MAX_ITEMS_PER_REQUEST;
    }

    return businesses;
};

export const findLayerIndex = (layerCollection: Layer[], alias: string) => {
    const key = `amenities-${alias}-preview`;
    return layerCollection.findIndex((layer) => {
        return layer.id === key;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const getUniqueCategories = (items: any) => {
    return chain(items)
        .map((child) => {
            return get(child, 'title.props.amenityKey');
        })
        .compact()
        .uniq()
        .value()
        .join(',');
};

export const fetchAmenities = async (categories: string, centerLatLng: CenterLatLng) => {
    const params = {
        latitude: centerLatLng.lat,
        longitude: centerLatLng.lng,
        radius: 3218,
        offset: 0,
        categories: categories,
    };
    return await queueFilterSearch(params, 0, 0);
};

export const createAmenitiesMapLayer = async (options: AmenityLayerMetadata, key: string) => {
    const businesses =
        options.searchString &&
        options.centerLatLng &&
        (await fetchAmenities(options.searchString[0], options.centerLatLng));
    if (businesses?.length && options?.alias) {
        const amenityLayer = await createAmenitiesLayer(key, businesses, options?.alias);
        return amenityLayer;
    }
};

export const createAmenitiesLayerItem = (
    layer: LibraryAmenitiesSaveState
): LibraryLayerTreeItem => {
    const options = isString(layer.options) ? JSON.parse(layer.options) : layer.options;
    const metadata: AmenityLayerMetadata = {
        centerLatLng: options?.centerLatLng,
        alias: options?.alias,
        searchString: options?.searchString ?? ([] as string[]),
    };

    const key = layer.guid ?? `Amenity--${nanoid()}`;
    return {
        key: key,
        id: layer.id ?? 0,
        title: layer.name,
        itemType: 'Amenity',
        metadata: metadata,
        isLeaf: true,
        checked: layer?.active,
    } as LibraryLayerTreeItem;
};

export const getAmenityLayerNodeBounds = (libraryItem: AmenityTreeItem) => {
    const metadata = libraryItem.metadata;
    if (metadata) {
        return (
            metadata.centerLatLng &&
            new Point({ latitude: metadata.centerLatLng.lat, longitude: metadata.centerLatLng.lng })
        );
    }
};
