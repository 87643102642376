import Geometry from '@arcgis/core/geometry/Geometry';
import Point from '@arcgis/core/geometry/Point';
import { BlockBlobClient } from '@azure/storage-blob';
import { produce } from 'immer';
import Papa, { ParseResult } from 'papaparse';

import { presetColors } from 'components/ColorPicker/ColorPickerComponent';
import { CsvLayerDto } from 'components/RefreshedUI/RefreshedLayers/RefreshedCSVLayer/RefreshedCSVLayer';
import { CsvCustomPosition, CsvLayerMetadata, CsvLayerStyle } from 'types/Layers/CsvLayerMetadata';
import { LibraryCsvLayerSaveState } from 'types/Layers/LibraryItemSaveState';
import LibraryLayerTreeItem, { CsvLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import endpoints from 'utils/apiClient/endpoints';
import { nanoid } from 'utils/idUtils';

export const parseCsv = (
    file: string
): Promise<Papa.ParseResult<Record<string, string | undefined>>> => {
    return new Promise<Papa.ParseResult<Record<string, string | undefined>>>((resolve, reject) => {
        Papa.parse<Record<string, string | undefined>>(file, {
            header: true,
            complete: (results) => resolve(results),
            error: (error: Error) => reject(error),
        });
    });
};

export const getDefaultCsvLayerStyles = (): CsvLayerStyle => ({
    showPin: true,
    showLabel: false,
    pinSize: 48,
    pinHeight: 100,
    pinColor: presetColors[0],
    pinIcon: 'location_on',
    pinType: 'generic',
});

export const loadCsvLayerItem = (layer: LibraryCsvLayerSaveState) => {
    return {
        key: layer.guid,
        id: layer.id,
        title: layer.name,
        itemType: 'CsvLayer',
        checked: layer.active,
        children: layer.children?.map((child) => ({ ...child, ownerId: layer.guid })),
        legendEnabled: true,
        showTotalTag: true,
        metadata: {
            layerId: layer.layerId,
            titleField: layer.titleField,
            latitudeField: layer.latitudeField,
            longitudeField: layer.longitudeField,
            labelField: layer.labelField,
            displayFields: layer.displayFields,
            customPositions: layer.customPositions,
            styles: {
                ...getDefaultCsvLayerStyles(),
                ...layer.styles,
            },
        },
    } as LibraryLayerTreeItem;
};

export const createCsvLayerItem = (
    name: string,
    metadata: Omit<CsvLayerMetadata, 'styles'>
): CsvLayerTreeItem => {
    const key = `CsvLayer--${nanoid()}`;

    return {
        key,
        id: metadata.layerId,
        title: name,
        itemType: 'CsvLayer',
        checked: true,
        children: [],
        legendEnabled: true,
        showTotalTag: true,
        metadata: {
            ...metadata,
            styles: { ...getDefaultCsvLayerStyles(), showLabel: !!metadata.labelField },
        },
    };
};

export const createChildren = (
    libraryItem: LibraryLayerTreeItem,
    objectIds: string[],
    titles: string[]
): LibraryLayerTreeItem[] => {
    return objectIds.map((oid, index) => {
        const title = titles.at(index);
        const formattedTitle = title || `Property ${parseInt(oid, 10)}`;
        return {
            key: `${libraryItem.key}--${oid}`,
            id: parseInt(oid),
            title: formattedTitle,
            itemType: 'CsvLayer',
            checked: libraryItem.checked,
            ownerId: libraryItem.key,
        };
    });
};

export const getCsvLayerSignedUrl = async (id: number): Promise<{ url: string }> => {
    const response = await endpoints.csv.signedUrl.get({
        templateValues: {
            id,
        },
    });

    return await response.json();
};

export const getParsedCsvForLayer = async (
    layerId: number,
    csvLayerMetadata:
        | Pick<CsvLayerMetadata, 'customPositions' | 'latitudeField' | 'longitudeField'>
        | undefined,
    options?: { signal: AbortSignal; abortReason: unknown }
): Promise<ParseResult<Record<string, string | undefined>>> => {
    const { url } = await getCsvLayerSignedUrl(layerId);
    const fetchResponse = await fetch(url, { signal: options?.signal });
    const text = await fetchResponse.text();
    // Check if cancelled before parsing CSV
    if (options?.signal.aborted) {
        throw options.abortReason;
    }
    const parsedCsvData = await parseCsv(text);
    if (
        csvLayerMetadata?.customPositions &&
        csvLayerMetadata.latitudeField &&
        csvLayerMetadata.longitudeField
    ) {
        parsedCsvData.data = applyCustomPositionsToCsvData(
            parsedCsvData.data,
            csvLayerMetadata.customPositions,
            csvLayerMetadata.latitudeField,
            csvLayerMetadata.longitudeField
        );
    }
    return parsedCsvData;
};

export function applyCustomPositionsToCsvData(
    data: Record<string, string | undefined>[],
    customPositions: Record<number, CsvCustomPosition | undefined>,
    latitudeField: string,
    longitudeField: string
) {
    return produce(data, (data) => {
        data?.forEach((row, i) => {
            if (customPositions[i]) {
                row[latitudeField] = String(customPositions[i].latitude);
                row[longitudeField] = String(customPositions[i].longitude);
            }
        });
    });
}

export const saveCsvLayer = async (csvLayer: CsvLayerDto): Promise<number> => {
    const saveResponse = await endpoints.csv.save.post({
        fetchOptions: {
            body: JSON.stringify(csvLayer),
        },
    });

    if (!saveResponse.ok) {
        throw new Error('Failed to save csv layer');
    }

    return parseInt(await saveResponse.text());
};

export const getCsvLayerUploadUrl = async (id: number) => {
    const uploadUrlResponse = await endpoints.csv.uploadUrl.get({
        templateValues: {
            id,
        },
    });

    if (!uploadUrlResponse.ok) {
        throw new Error('Failed to get upload url');
    }

    return await uploadUrlResponse.text();
};

export const uploadCsvToBlobStorage = async (
    sasUrl: string,
    file: File,
    onProgress: (event: { loadedBytes: number }) => void
): Promise<void> => {
    try {
        const blobClient = new BlockBlobClient(sasUrl);
        const metadata = { FileName: file.name };

        await blobClient.uploadData(file, {
            onProgress,
            blobHTTPHeaders: {
                blobContentType: file.type,
                blobContentDisposition: `attachment; filename="${encodeURIComponent(file.name)}"`,
            },
            metadata,
        });
    } catch (error) {
        console.error('Error:', error);
    }
};

export const deleteCsvLayer = async (id: number): Promise<void> => {
    const deleteResponse = await endpoints.csv.remove.delete({
        templateValues: {
            id,
        },
    });

    if (!deleteResponse.ok) {
        throw new Error('Failed to delete csv layer');
    }
};

export const getCsvLayerBounds = async (
    libraryItem: CsvLayerTreeItem
): Promise<Geometry[] | null> => {
    try {
        const metadata = libraryItem.metadata as CsvLayerMetadata;
        const csvData = await getParsedCsvForLayer(libraryItem.id, metadata);

        const points = csvData.data
            .map((rowData): [number, number] => [
                Number(rowData[metadata.longitudeField]),
                Number(rowData[metadata.latitudeField]),
            ])
            .filter((pos) => !isNaN(pos[0]) && !isNaN(pos[1]));

        if (points.length > 0) {
            return points.map((point) => new Point({ longitude: point[0], latitude: point[1] }));
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error in getCsvLayerBounds:', error);
        throw error;
    }
};
