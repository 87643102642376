import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

export enum LeftPanelKeys {
    Presentation = 'presentation',
    SinglePropertyView = 'single_property_view',
}

export const LeftPanelLabels = {
    [LeftPanelKeys.Presentation]: 'Presentation',
    [LeftPanelKeys.SinglePropertyView]: 'Single Property View',
};

export interface LeftPanelItem {
    name: LeftPanelKeys;
    hiddenInPresentationMode?: boolean;
}

const leftPanels: {
    [key in LeftPanelKeys]: LeftPanelItem;
} = {
    [LeftPanelKeys.Presentation]: {
        name: LeftPanelKeys.Presentation,
        hiddenInPresentationMode: true,
    },
    [LeftPanelKeys.SinglePropertyView]: {
        name: LeftPanelKeys.SinglePropertyView,
    },
};

interface LeftPanelState {
    activePanel: LeftPanelItem | null;
}

const initialState: LeftPanelState = {
    activePanel: leftPanels[LeftPanelKeys.Presentation],
};

export const leftPanelSlice = createSlice({
    name: 'leftPanel',
    initialState,
    reducers: {
        setActiveLeftPanel: (state, action: PayloadAction<LeftPanelKeys>) => {
            const panel = leftPanels[action.payload];
            state.activePanel = panel;
        },

        toggleActiveLeftPanel: (state, action: PayloadAction<LeftPanelKeys>) => {
            const panel = leftPanels[action.payload];
            state.activePanel = state.activePanel?.name === panel.name ? null : panel;
        },

        clearLeftPanel: (state) => {
            state.activePanel = null;
        },

        resetLeftPanel: (state) => {
            state.activePanel = leftPanels[LeftPanelKeys.Presentation];
        },
    },
});

export const { setActiveLeftPanel, clearLeftPanel, toggleActiveLeftPanel, resetLeftPanel } =
    leftPanelSlice.actions;

const selectLeftPanel = (state: RootState): LeftPanelState => state.leftPanel;

export const selectActiveLeftPanel = (state: RootState): LeftPanelItem | null =>
    state.leftPanel.activePanel;

export const selectIsSinglePropertyViewVisible = createSelector(selectLeftPanel, (leftPanel) => {
    return leftPanel.activePanel?.name === LeftPanelKeys.SinglePropertyView;
});

export const getActiveLeftPanelKey = (state: RootState): LeftPanelKeys | null =>
    state.leftPanel.activePanel?.name ?? null;

export default leftPanelSlice.reducer;
