import esriConfig from '@arcgis/core/config';
import Extent from '@arcgis/core/geometry/Extent';
import Geometry from '@arcgis/core/geometry/Geometry';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import esriId from '@arcgis/core/identity/IdentityManager';
import Layer from '@arcgis/core/layers/Layer';
import PortalItem from '@arcgis/core/portal/PortalItem';
import esriRequest from '@arcgis/core/request';

import {
    BLACKBIRD_IMAGE_PROXY,
    BLACKBIRD_PROXY_URL_PREFIX,
    MAPIT_TOKEN,
    MAPIT_TOKEN_EXPIRES,
    MAPIT_URL,
} from 'constants/map.constants';
import { CspBoundingBox } from 'types/CspTypes';
import ExtendedLayer from 'types/esri/ExtendedLayer';
import { LayerBounds } from 'types/Layers/KmlLayerMetadata';
import endpoints from 'utils/apiClient/endpoints';
import config from './config';

export async function sendRequest(url: string, query?: object) {
    const controller = new AbortController();
    const signal = controller.signal;
    const _query = Object.assign(
        {
            f: 'json',
        },
        query
    );

    try {
        const response = await esriRequest(url, {
            query: _query,
            signal,
            responseType: 'json',
        });
        return response.data;
    } catch (err) {
        if ((err as DOMException).name === 'AbortError') {
            console.log('Request aborted');
        } else {
            console.error('Error encountered', err);
        }
    }

    // Abort requests that are aware of the controller's signal
    controller.abort();
}

export const registerToken = async (tokenServiceDomain: string) => {
    esriConfig.portalUrl = tokenServiceDomain;
    const token = localStorage.getItem(MAPIT_TOKEN);
    const tokenExpiresAt = localStorage.getItem(MAPIT_TOKEN_EXPIRES);

    if (token && tokenExpiresAt && new Date(Number(tokenExpiresAt)).getTime() > Date.now()) {
        registerMapItToken(token);
        setupImageProxy();
        return token;
    }

    return await refreshToken(tokenServiceDomain);
};

const TOKEN_EXPIRATION_BUFFER_MINUTES = 2; // Time buffer before token expiration in minutes

/**
 * To generate MapIT token locally, use mapItTokenUrl = 'http://localhost:8080/v2/api/MapIt/token'
 */
export async function refreshToken(tokenServiceDomain: string, refresh = false) {
    try {
        const output = await endpoints.mapIt.token.get({
            queryParameters: { domain: tokenServiceDomain },
        });
        const result = await output.json();

        if (!result.token || !result.expires) return;
        if (refresh) esriConfig.request.interceptors = [];

        localStorage.setItem(MAPIT_TOKEN, result.token);
        localStorage.setItem(MAPIT_TOKEN_EXPIRES, result.expires);

        registerMapItToken(result.token);
        setupImageProxy();

        // Calculate the refresh interval
        const tokenExpirationTime = new Date(result.expires).getTime();
        const now = Date.now();
        const timeBeforeTokenExpires = tokenExpirationTime - now;
        const refreshInterval =
            timeBeforeTokenExpires - TOKEN_EXPIRATION_BUFFER_MINUTES * 60 * 1000;
        // Set timer to refresh token before it expires
        setTimeout(function () {
            refreshToken(tokenServiceDomain, true);
        }, refreshInterval);
    } catch (error) {
        console.error('Failed to get MapIT online token', error);
        // Implement retry logic if required or notify the user about the token refresh failure
    }
}

export const registerMapITEnterpriseUrl = async (tokenServiceDomain: string) => {
    try {
        const output = await endpoints.mapIt.token.get({
            queryParameters: { domain: tokenServiceDomain },
        });
        const result = await output.json();
        if (!result.token || !result.expires) return;
        registerTokenInterceptor([tokenServiceDomain], result.token);
        setupImageProxy();
    } catch (error) {
        console.error('failed to get MapIT enterprise token');
    }
};

function appendPath(basePath: string, appendedPath: string): string {
    basePath = basePath.at(-1) === '/' ? basePath : basePath + '/';
    appendedPath = appendedPath[0] === '/' ? appendedPath.substring(1) : appendedPath;

    return basePath + appendedPath;
}

function registerMapItToken(token: string): void {
    esriId.registerToken({
        server: appendPath(MAPIT_URL, 'sharing/rest'),
        token: token,
    });
}

function setupImageProxy(): void {
    esriConfig.request.proxyUrl = BLACKBIRD_IMAGE_PROXY;
    esriConfig.request.interceptors = esriConfig.request.interceptors ?? [];
    esriConfig.request.interceptors.push({
        urls: BLACKBIRD_PROXY_URL_PREFIX,
        before: function (params) {
            params.requestOptions.headers = params.requestOptions.headers ?? {};
            params.requestOptions.headers['Authorization'] = `Bearer ${config.accessToken}`;
            params.requestOptions.headers['Subscription-key'] =
                window.STATIC_CONFIG.blackbirdApiSubscriptionKey;
        },
    });
}

function registerTokenInterceptor(serviceUrls: string[], token?: string) {
    esriConfig.request.interceptors = esriConfig.request.interceptors ?? [];
    esriConfig.request.interceptors.push({
        urls: serviceUrls,
        // use the BeforeInterceptorCallback to add token to query
        before: function (params) {
            params.requestOptions.query = params.requestOptions.query ?? {};
            params.requestOptions.query.token = token;
        },
    });
}

export const createLayerFromPortalItem = (layerId: string): Promise<ExtendedLayer> => {
    return Layer.fromPortalItem({
        portalItem: new PortalItem({
            id: layerId,
        }),
    });
};

export const isCspBoundingBoxEmpty = (boundingBox: CspBoundingBox) => {
    if (!boundingBox) return true;
    const { topLeft, bottomRight } = boundingBox;
    return topLeft.lat == 0 && bottomRight.lat == 0 && topLeft.long == 0 && bottomRight.long == 0;
};
export const createExtentFromCspBoundingBox = (boundingBox: CspBoundingBox): Geometry => {
    const { topLeft, bottomRight } = boundingBox;
    const topLeftPoint = new Point({ latitude: topLeft.lat, longitude: topLeft.long });
    const bottomRightPoint = new Point({
        latitude: bottomRight.lat,
        longitude: bottomRight.long,
    });

    if (topLeftPoint.equals(bottomRightPoint)) {
        const webMercatorCorner = webMercatorUtils.geographicToWebMercator(topLeftPoint) as Point;
        const buffered = geometryEngine.buffer(webMercatorCorner, 150, 'meters') as Polygon;
        return buffered.extent;
    }
    return new Extent({
        xmin: topLeft.long,
        ymin: topLeft.lat,
        xmax: bottomRight.long,
        ymax: bottomRight.lat,
        spatialReference: {
            wkid: 4326,
        },
    });
};

export const createExtentFromKmlBounds = (bounds: LayerBounds): Geometry => {
    return new Extent({
        xmin: bounds.west,
        ymin: bounds.south,
        xmax: bounds.east,
        ymax: bounds.north,
        spatialReference: {
            wkid: 4326,
        },
    });
};

export const createCspBoundingBox = (extent: Extent): CspBoundingBox => {
    const modifiedExtent = webMercatorUtils.webMercatorToGeographic(extent) as Extent;
    const { xmin, ymin, xmax, ymax } = modifiedExtent;
    return {
        topLeft: {
            long: xmin,
            lat: ymax,
        },
        bottomRight: {
            long: xmax,
            lat: ymin,
        },
    };
};
