import SceneView from '@arcgis/core/views/SceneView';
import { Menu } from '@jll/react-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { usePolygonEditorActions } from 'components/polygonEditor/usePolygonEditorActions';
import { isDevPipelineSceneLayer } from 'helpers/devPipelineBuildingLayerHelper';
import { isOsmSceneLayer } from 'helpers/osmBuildingLayerHelper';
import { isBuildingEditsLayer } from 'helpers/polygonEditorHelper';
import { isMissingPolygonsPinsLayer } from 'helpers/searchHelper';
import { useMap } from 'hooks/MapProvider';
import { selectedGraphics } from 'store/polygonsLayerSlice';
import { setStreetViewVisibility } from 'store/streetMapViewSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { ContextMenuItem } from 'types/Layers/ContextMenu';
import { useHighlightSetActions } from '../HighlighSets/useHighlightSetActions';

interface MapContextMenuProps {
    view: SceneView | null;
}

export const DefaultMapContextMenu = ({ view: _view }: MapContextMenuProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentGraphics = useAppSelector(selectedGraphics);
    const { buildingEditing } = useFlags();

    const { hideMapContextMenu } = useMap();

    const { getMapContextMenuItems } = useHighlightSetActions();
    const { openPolygonEditor } = usePolygonEditorActions();

    const getPolygonEditsMenuItems = () => {
        return [
            ContextMenuItem({
                label: 'Edit Polygon',
                key: 'editPolygon',
                onClick: () => {
                    openPolygonEditor();
                },
            }),
        ];
    };

    const getRightClickItems = () => {
        return [
            ContextMenuItem({
                label: 'Street View',
                key: 'streetView',
                onClick: () => {
                    dispatch(setStreetViewVisibility(true));
                    hideMapContextMenu();
                },
            }),
        ];
    };

    const getMenuItems = () => {
        const items = getRightClickItems();

        if (currentGraphics.length) {
            items.push(ContextMenuItem({ type: 'divider' }));
            const layerId = currentGraphics[0].layer.id;
            if (
                isOsmSceneLayer(layerId) ||
                isDevPipelineSceneLayer(layerId) ||
                isBuildingEditsLayer(layerId)
            ) {
                items.push(...getMapContextMenuItems());
            } else if (
                buildingEditing &&
                (isMissingPolygonsPinsLayer(layerId) || isBuildingEditsLayer(layerId))
            ) {
                items.push(...getPolygonEditsMenuItems());
            }
        }
        return items;
    };

    return (
        <Menu
            selectable={false}
            style={{ width: 180, borderRadius: 4 }}
            items={getMenuItems()}
            id='context-menu'
        />
    );
};
