import Color from '@arcgis/core/Color';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';

import { EdgeStyle } from 'store/featuresSlice';
import { createOSMUniqueValueGroupsRenderer } from './osmStylesHelper';

export const OSM_POLYGON_HEX_COLOR = '#FFFFFF';
export const OSM_POLYGON_COLOR = new Color(OSM_POLYGON_HEX_COLOR);
export const OSM_SCENE_LAYER_ID = 'osm-scene-layer';
export const OSM_PINS_LAYER_ID = 'osm-highlight-pins';

export const OSM_WALL_COLOR = new Color('#00fffb');
export const OSM_EDGE_STYLE: EdgeStyle = {
    visible: true,
    size: 0.25,
    type: 'solid',
    color: '#000000',
};

export function isOsmSceneLayer(layerId: string): boolean {
    return layerId === OSM_SCENE_LAYER_ID;
}

export const createOsmBuildingLayer = (
    sceneLayerId: string,
    defaultColor = OSM_POLYGON_COLOR
): SceneLayer => {
    return new SceneLayer({
        id: OSM_SCENE_LAYER_ID,
        url: 'https://basemaps3d.arcgis.com/arcgis/rest/services/OpenStreetMap3D_Buildings_v1/SceneServer',
        popupEnabled: false,
        outFields: ['*'],
        legendEnabled: true,
        renderer: createOSMUniqueValueGroupsRenderer([] as UniqueValueGroup[], {
            defaultColor,
        }),
        title: 'OpenStreetMap Buildings',
    });
};
