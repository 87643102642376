import Graphic from '@arcgis/core/Graphic';

import { SceneLayerSource } from 'components/HighlighSets/buildingSelectionStyleHelpers';
import { isDevPipelineSceneLayer } from 'helpers/devPipelineBuildingLayerHelper';
import { isBuildingEditsLayer } from 'helpers/polygonEditorHelper';
import isDefined from 'utils/isDefined';

export interface BuildingGeometryRef {
    layerType: SceneLayerSource;
    id: number;
}

export function buildingGeometryRefEqual(
    a: BuildingGeometryRef | undefined,
    b: BuildingGeometryRef | undefined
): boolean {
    return a === b || (!!a && !!b && a.layerType === b.layerType && a.id === b.id);
}

export function buildingGeometryRefIdOfType(
    a: BuildingGeometryRef | undefined,
    layerType: string
): number | undefined {
    return a?.layerType === layerType ? a.id : undefined;
}

export function buildingGeometryRefFromGraphic(graphic: Graphic): BuildingGeometryRef | undefined {
    if (isDevPipelineSceneLayer(graphic.layer.id)) {
        return { layerType: 'dev-pipeline', id: graphic.attributes['BlackbirdId'] };
    } else if (isBuildingEditsLayer(graphic.layer.id)) {
        return { layerType: 'building-edits-layer', id: graphic.attributes['BlackbirdId'] };
    } else if (graphic.attributes.OSMID) {
        return { layerType: 'osm', id: graphic.attributes.OSMID };
    }
    return undefined;
}

export class BuildingGeometryRefSet {
    private table: Record<number, Array<string> | undefined> = {};

    constructor(items?: Iterable<BuildingGeometryRef>) {
        if (!isDefined(items)) {
            return;
        }

        for (const item of items) {
            this.add(item);
        }
    }

    static fromJSON(table: Record<number, Array<string>>) {
        const refSet = new BuildingGeometryRefSet();
        refSet.table = table;

        return refSet;
    }

    add(ref: BuildingGeometryRef): this {
        let tableEntry = this.table[ref.id];
        if (!isDefined(tableEntry)) {
            this.table[ref.id] = tableEntry = [];
        }

        if (!tableEntry.includes(ref.layerType)) {
            tableEntry.push(ref.layerType);
        }

        return this;
    }

    delete(ref: BuildingGeometryRef): this {
        let tableEntry = this.table[ref.id];
        if (!isDefined(tableEntry)) {
            return this;
        }

        this.table[ref.id] = tableEntry = tableEntry.filter(
            (layerType) => layerType === ref.layerType
        );

        if (tableEntry?.length === 0) {
            delete this.table[ref.id];
        }

        return this;
    }

    has(ref: BuildingGeometryRef | undefined): boolean {
        return isDefined(ref) && !!this.table[ref.id]?.includes(ref.layerType);
    }

    toJSON() {
        return this.table;
    }
}
