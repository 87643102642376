import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Layer from '@arcgis/core/layers/Layer';
import SceneLayerView from '@arcgis/core/views/layers/SceneLayerView';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { RootState } from './index';
import { clearPresentation } from './presentationSlice';

interface PolygonsLayerSliceState {
    layer?: Layer;
    floorSceneLayer?: Layer;
    modifiedPolygonsLayer?: GraphicsLayer;
    sceneLayerView?: SceneLayerView;
    graphicsToUpdate: Graphic[];
    selectedGraphics: Graphic[];
    openPolygonEditor?: boolean;
    missingPolygonsLayer?: Layer;
    editedMarketSpherePropertyId: number[];
}

const initialState: PolygonsLayerSliceState = {
    graphicsToUpdate: [] as Graphic[],
    selectedGraphics: [] as Graphic[],
    editedMarketSpherePropertyId: [],
};

export const polygonsLayerSlice = createSlice({
    name: 'polygonsLayer',
    initialState,
    reducers: {
        addGraphicsToUpdate(state, action: PayloadAction<Graphic[]>) {
            state.graphicsToUpdate = action.payload;
        },
        replaceCurrentGraphics(state, action: PayloadAction<Graphic[]>) {
            state.graphicsToUpdate = action.payload;
        },
        addPolygonToModifiedLayer(state, action: PayloadAction<Graphic[]>) {
            const graphics = action.payload;
            graphics?.forEach((graphic) => {
                state.modifiedPolygonsLayer?.add(graphic);
            });
        },
        removeEditedPolygon(state, action: PayloadAction<Graphic>) {
            const graphic = action.payload;
            if (graphic) state.modifiedPolygonsLayer?.remove(graphic);
        },
        setSelectedGraphics(state, action: PayloadAction<Graphic[]>) {
            state.selectedGraphics = action.payload;
        },
        cleanSelectedGraphics(state) {
            state.selectedGraphics = [];
        },
        setPolygonsLayer(state, action: PayloadAction<Layer>) {
            state.layer = action.payload;
        },
        setFloorSceneLayer(state, action: PayloadAction<Layer>) {
            state.floorSceneLayer = action.payload;
        },
        setModifiedPolygonsLayer(state, action: PayloadAction<GraphicsLayer>) {
            state.modifiedPolygonsLayer = action.payload;
        },
        setMissingPolygonsLayer(state, action: PayloadAction<Layer>) {
            state.missingPolygonsLayer = action.payload;
        },
        hideFeaturesFromMap(state, action) {
            const featureIds = action.payload;
            if (featureIds?.length && state.sceneLayerView?.layer)
                state.sceneLayerView.layer.definitionExpression = `PropId not in (${featureIds.join(
                    ','
                )})`;
        },
        showAllFeaturesFromMap(state) {
            if (state.sceneLayerView?.layer) state.sceneLayerView.layer.definitionExpression = '';
        },
        setPolygonLayerVisibility(state, action: PayloadAction<boolean>) {
            if (state.layer) state.layer.visible = action.payload;
        },
        setFloorsLayerVisibility(state, action: PayloadAction<boolean>) {
            if (state.floorSceneLayer) state.floorSceneLayer.visible = action.payload;
        },
        setOpenPolygonEditor(state, action: PayloadAction<boolean>) {
            state.openPolygonEditor = action.payload;
        },
        setEditedMarketSpherePropertyIds(state, action: PayloadAction<number[]>) {
            const marketSpherePropertyIds = [
                ...state.editedMarketSpherePropertyId,
                ...action.payload,
            ];
            state.editedMarketSpherePropertyId = uniq(marketSpherePropertyIds);
        },
        removeEditedMarketSpherePropertyIds(state, action: PayloadAction<number[]>) {
            state.editedMarketSpherePropertyId = state.editedMarketSpherePropertyId.filter(
                (id) => !action.payload.includes(id)
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const {
    addGraphicsToUpdate,
    addPolygonToModifiedLayer,
    hideFeaturesFromMap,
    showAllFeaturesFromMap,
    setPolygonsLayer,
    setFloorSceneLayer,
    setModifiedPolygonsLayer,
    removeEditedPolygon,
    replaceCurrentGraphics,
    setPolygonLayerVisibility,
    setFloorsLayerVisibility,
    setMissingPolygonsLayer,
    setSelectedGraphics,
    cleanSelectedGraphics,
    setOpenPolygonEditor,
    setEditedMarketSpherePropertyIds,
    removeEditedMarketSpherePropertyIds,
} = polygonsLayerSlice.actions;

export const selectFloorSceneLayer = (state: RootState): Layer | undefined => {
    return state.polygonsLayer?.floorSceneLayer;
};

export const selectMissingPolygonsLayer = (state: RootState): Layer | undefined => {
    return state.polygonsLayer?.missingPolygonsLayer;
};

export const selectModifiedPolygonsLayer = (state: RootState): GraphicsLayer | undefined => {
    return state.polygonsLayer?.modifiedPolygonsLayer;
};

export const selectGraphicsToUpdate = (state: RootState): Graphic[] => {
    return state.polygonsLayer?.graphicsToUpdate;
};

export const selectSceneLayerView = (state: RootState): SceneLayerView | undefined => {
    return state.polygonsLayer?.sceneLayerView;
};

export const selectedGraphics = (state: RootState): Graphic[] => {
    return state.polygonsLayer?.selectedGraphics;
};

export const selectOpenPolygonEditor = (state: RootState): boolean | undefined => {
    return state.polygonsLayer?.openPolygonEditor;
};

export const selectEditedMarketSpherePropertyId = (state: RootState): number[] => {
    return state.polygonsLayer?.editedMarketSpherePropertyId;
};

export default polygonsLayerSlice.reducer;
