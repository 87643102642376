import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from 'store';
import { LeftPanelKeys, resetLeftPanel, setActiveLeftPanel } from './leftPanelSlice';
import { clearPresentation } from './presentationSlice';

interface SinglePropertyViewState {
    value: {
        propertyId: string;
    };
}

const initialState: SinglePropertyViewState = {
    value: { propertyId: '' },
};

export const setSinglePropertyView = (visible: boolean) => (dispatch: AppDispatch) => {
    if (visible) {
        dispatch(setActiveLeftPanel(LeftPanelKeys.SinglePropertyView));
    } else {
        dispatch(resetLeftPanel());
    }
};

export const singlePropertyViewSlice = createSlice({
    name: 'singlePropertyView',
    initialState,
    reducers: {
        setSelectedPropertyId(state, action: PayloadAction<string>) {
            state.value.propertyId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const { setSelectedPropertyId } = singlePropertyViewSlice.actions;

export const selectedPropertyId = (state: RootState): string => {
    return state.singlePropertyView?.value?.propertyId;
};

export default singlePropertyViewSlice.reducer;
