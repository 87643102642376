import { get } from 'lodash';

import { MarketViewLayerMetadata } from 'types/Layers/MarketViewLayerMetadata';
import { LibraryPropertyViewSaveState } from '../types/Layers/LibraryItemSaveState';
import { MarketViewTreeItem } from '../types/Layers/LibraryLayerTreeItem';
import { createExtentFromCspBoundingBox, isCspBoundingBoxEmpty } from '../utils/esriUtils';
import { nanoid } from '../utils/idUtils';
import isDefined from '../utils/isDefined';

export const viewResponsePaths: Record<MarketViewField, string> = {
    propertyType: 'propertyType',
    buildingClass: 'buildingClass',
    yearBuilt: 'yearBuilt',
    devPipeline: 'propertyStatus',
};
export type buildingIdType = 'osmId' | 'marketSpherePropertyId';
export type MarketViewField = 'propertyType' | 'buildingClass' | 'yearBuilt' | 'devPipeline';

export const yearBuiltGroups = [
    [0, 1950],
    [1950, 1959],
    [1960, 1969],
    [1970, 1979],
    [1980, 1989],
    [1990, 1999],
    [2000, 2009],
    [2010, 2019],
    [2020, 2024],
    [2025, 2100],
];

export const marketViewFieldNameToLabel = (field: MarketViewField) =>
    field.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase());

export const createMarketViewSaveState = (libraryItem: MarketViewTreeItem) => {
    const { metadata, children, checked } = libraryItem;
    if (children) {
        const items = children
            .map((item) => {
                const metadata = (item as MarketViewTreeItem).metadata;
                if (!metadata) return;
                const { markets, conditions, fieldName } = metadata;
                return JSON.stringify({
                    guid: item.key,
                    markets,
                    name: item.title,
                    conditions,
                    fieldName,
                    itemType: 'PropertyView',
                    active: item.checked,
                });
            })
            .filter(isDefined);
        const anyChecked = children.some((item) => item.checked);
        return {
            children: JSON.stringify(items),
            active: anyChecked,
        };
    } else if (metadata) {
        return {
            filters: JSON.stringify(metadata),
            active: checked,
        };
    }
};

const createMarketViewChildrenFromSaveState = (children: string) => {
    if (!children) return;
    const childrenList = JSON.parse(children) as string[];
    return childrenList.map((child) => {
        const metadata = JSON.parse(child);
        return {
            ...createMarketViewLibraryItem(metadata),
            checked: get(metadata, 'active') ?? false,
            key: metadata.guid ?? `PropertyView--${nanoid()}`,
        } as MarketViewTreeItem;
    });
};

export const createMarketViewLayerFromSavedState = (
    layer: LibraryPropertyViewSaveState
): MarketViewTreeItem => {
    const itemType = 'PropertyView';
    const { guid, name, active, children, filters } = layer;
    const key = guid ?? `${itemType}--${nanoid()}`;

    if (filters) {
        const metadata = JSON.parse(filters);
        return {
            ...createMarketViewLibraryItem(metadata),
            key: key,
            checked: active,
            title: name,
        } as MarketViewTreeItem;
    } else {
        const items = createMarketViewChildrenFromSaveState(children) ?? [];
        return {
            itemType,
            key,
            title: name,
            checked: active,
            children: items,
        } as MarketViewTreeItem;
    }
};

const marketViewLabel = (viewType: MarketViewField) => {
    switch (viewType) {
        case 'propertyType':
            return 'Property Type';
        case 'buildingClass':
            return 'Building Class';
        case 'devPipeline':
            return 'Dev Pipeline';
        case 'yearBuilt':
            return 'Year Built';
    }
};

export const generateItemTitle = (viewType: MarketViewField, selectedMarkets: string[]) => {
    const firstMarket = selectedMarkets[0];
    const marketLabel = selectedMarkets.length > 1 ? `${firstMarket} etc.` : firstMarket;
    const viewLabel = marketViewLabel(viewType);
    return `${marketLabel} ${viewLabel}`;
};

export const createMarketViewLibraryItem = (
    metadata: MarketViewLayerMetadata
): MarketViewTreeItem => {
    return {
        id: 0,
        key: `PropertyView--${nanoid()}`,
        title: metadata.name,
        itemType: 'PropertyView',
        checked: true,
        metadata: metadata,
    };
};

export const getMarketViewBounds = (libraryItem: MarketViewTreeItem) => {
    const metadata = libraryItem.metadata as MarketViewLayerMetadata;
    const boundingBox = metadata.boundingBox;
    if (!boundingBox || isCspBoundingBoxEmpty(boundingBox)) return;
    return createExtentFromCspBoundingBox(boundingBox);
};
