import moment from 'moment';

import {
    BuildingClass,
    ListOfMarketHierarchyParameterInput,
    PropertyStatus,
    PropertyTypeSourceValue,
} from 'types/CspInputs';

export const defaultPropertyFilters: PropertyFilterFields = {
    buildingSize: {
        min: undefined,
        max: undefined,
    },
    yearBuilt: {
        min: undefined,
        max: undefined,
    },
    buildingClass: [],
    markets: [],
    propertyTypes: [],
    buildingStatus: [],
    submarkets: [],
    totalArea: {
        from: 0,
        to: 0,
    },
    buildingOwner: [],
    usage: {
        values: [
            {
                subType: [],
            },
        ],
    },
    indicator: {
        values: [
            {
                inStatistics: undefined,
            },
        ],
    },
};

export const defaultAvailabilityFilters: AvailabilityFilters = {
    buildingContigSpace: {
        min: undefined,
        max: undefined,
    },
    askingRentMax: {
        min: undefined,
        max: undefined,
    },
    leaseType: [],
    properties: {
        values: [
            {
                buildingClass: [],
                buildingStatus: [],
                propertyType: [],
            },
        ],
    },
    marketsHierarchy: {
        values: [
            {
                marketLevel1: '',
                marketLevel2: '',
                marketLevel3: '',
                subMarketCluster: '',
            },
        ],
    },
    isFullFloor: undefined,
};

export const defaultSalesFilters: SalesFilters = {
    properties: {
        values: [
            {
                buildingStatus: [],
                propertyType: [],
            },
        ],
    },
    closeDate: {
        from: undefined,
        to: undefined,
    },
    pricePerArea: {
        min: undefined,
        max: undefined,
    },
    assetSalePrice: {
        min: undefined,
        max: undefined,
    },
};

export const defaultLeaseFilters: LeaseFilters = {
    properties: {
        values: [
            {
                propertyType: [],
                propertyStatus: [],
                marketsHierarchy: {
                    values: [
                        {
                            marketLevel1: '',
                            marketLevel2: '',
                            marketLevel3: '',
                            subMarketCluster: '',
                        },
                    ],
                },
            },
        ],
    },
    companies: {
        values: [
            {
                companyName: [],
                industryCluster: '',
            },
        ],
    },
    leaseType: [],
    executionDate: {
        from: undefined,
        to: undefined,
    },
    expirationDate: {
        from: undefined,
        to: undefined,
    },
    totalArea: {
        min: undefined,
        max: undefined,
    },
    firstYearRentAmount: {
        min: undefined,
        max: undefined,
    },
};

export interface MinMaxOutput {
    min: number | undefined;
    max: number | undefined;
}

export function isMinMaxDefined(range: MinMaxOutput | undefined): range is MinMaxOutput {
    return range != null && (range.min != null || range.max != null);
}

export function cspRangeFromMinMax(range: MinMaxOutput): {
    from: number | undefined;
    to: number | undefined;
} {
    return {
        from: range.min,
        to: range.max,
    };
}

export interface DateTimeOutput {
    from: string | undefined | null;
    to: string | undefined | null;
}

export function isDateTimeRangeDefined(range: DateTimeOutput | undefined): range is DateTimeOutput {
    return range != null && (range.from != null || range.to != null);
}

export function dateTimeRangeFromValueArray(
    values: string[],
    format: string = 'MM-DD-YYYY'
): DateTimeOutput {
    return {
        from: values[0] ? moment(values[0], format).toISOString() : undefined,
        to: values[1] ? moment(values[1], format).toISOString() : undefined,
    };
}

export interface AvailabilityFilters {
    buildingContigSpace?: MinMaxOutput;
    askingRentMax?: MinMaxOutput;
    leaseType?: string[];
    submarkets?: string[];
    submarketClusters?: string[];
    properties?: PropertyFilter;
    marketsHierarchy?: ListOfMarketHierarchyParameterInput;
    areaMax?: MinMaxOutput;
    isFullFloor?: boolean;
}

export interface AvailabilityFiltersLegacy {
    areaMax?: MinMaxOutput;
    buildingContigSpace?: MinMaxOutput;
    askingRentMax?: MinMaxOutput;
    spaceUseType?: string[];
    buildingClass?: string[];
    buildingStatus?: string[];
    submarkets?: string[];
    submarketClusters?: string[];
    propertyTypes?: string[];
    markets?: string[];
}

export interface PropertyFilterFields {
    propertyTypes?: PropertyTypeSourceValue[];
    markets?: string[];
    submarkets?: string[];
    buildingClass?: BuildingClass[];
    buildingStatus?: PropertyStatus[];
    buildingSize?: MinMaxOutput;
    yearBuilt?: MinMaxOutput;
    totalArea?: NumericRangeInput;
    buildingOwner?: string[];
    usage?: UsageFilter;
    indicator?: IndicatorFilter;
}

export interface SalesFilters {
    properties?: PropertyFilter;
    closeDate: DateTimeOutput;
    pricePerArea?: MinMaxOutput;
    assetSalePrice?: MinMaxOutput;
    buyers?: string[];
    sellers?: string[];
}

export interface LeaseFilters {
    companies?: Companies;
    industryCluster?: string[];
    leaseType?: string[];
    executionDate?: DateTimeOutput;
    expirationDate?: DateTimeOutput;
    properties?: PropertyFilter;
    totalArea?: MinMaxOutput;
    firstYearRentAmount?: MinMaxOutput;
}
export interface PropertyMarket {
    market: string;
    submarket: string;
    propertyType: string;
}

export interface PropertyFilter {
    values: {
        buildingStatus?: string[];
        propertyType?: string[];
        buildingClass?: string[];
        propertyStatus?: string[];
        marketsHierarchy?: ListOfMarketHierarchyParameterInput;
        propertyTypeSourceValue?: string[];
    }[];
}

export interface NumericRangeInput {
    from: number;
    to: number;
}

export interface Companies {
    values: {
        companyName?: string[];
        industryCluster?: string;
    }[];
}

export interface UsageFilter {
    values: {
        subType?: string[];
    }[];
}

export interface IndicatorFilter {
    values: {
        inStatistics?: boolean;
    }[];
}
