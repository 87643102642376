import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PORTAL_USER_URL } from 'constants/map.constants';
import { GISUser } from 'helpers/mapItLayerHelper';
import { RootState } from 'store';
import type { LoadStatus } from 'types/LoadStatus';
import { sendRequest } from 'utils/esriUtils';

interface GISUserSliceState {
    value?: GISUser;
    status: LoadStatus;
}

const initialState: GISUserSliceState = {
    status: 'idle',
};

export const fetchCurrentUser = createAsyncThunk(
    'gisUser/fetchUser',
    async (userPrincipalName: string) => {
        const username = `${userPrincipalName}_JLL`;
        return await sendRequest(`${PORTAL_USER_URL}${username}`);
    }
);

export const GISUserSlice = createSlice({
    name: 'gisUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCurrentUser.rejected, (state) => {
                throw new Error('User failed to load');
                state.status = 'failed';
            })
            .addCase(fetchCurrentUser.fulfilled, (state, action) => {
                state.status = 'ready';
                state.value = action.payload;
            });
    },
});

export const selectCurrentGISUser = (state: RootState): GISUser | undefined => {
    return state.gisUser.value;
};

export default GISUserSlice.reducer;
