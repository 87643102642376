import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

export interface TableHighlightSetBuilding {
    csvLayerId: number;
    displayFields: string[];
    name: string;
    csvItemIndex: number;
}

interface TableHighlightSetSliceState {
    selectedBuilding: TableHighlightSetBuilding | null;
}

const initialState: TableHighlightSetSliceState = {
    selectedBuilding: null,
};

export const tableHighlightSetSlice = createSlice({
    name: 'tableHighlightSet',
    initialState,
    reducers: {
        setSelectedBuilding: (
            state,
            { payload }: { payload: TableHighlightSetBuilding | null }
        ) => {
            state.selectedBuilding = payload;
        },
    },
});

export const { setSelectedBuilding } = tableHighlightSetSlice.actions;

export const selectSelectedBuilding = (state: RootState): TableHighlightSetBuilding | null => {
    return state.tableHighlightSet.selectedBuilding;
};

export default tableHighlightSetSlice.reducer;
