import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { CsvLayerMetadata } from 'types/Layers/CsvLayerMetadata';
import { CsvLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';

interface CsvStyleSliceState {
    originalState: CsvLayerMetadata | null;
    editingLayer: CsvLayerTreeItem | null;
}

const initialState: CsvStyleSliceState = {
    originalState: null,
    editingLayer: null,
};

export const csvStyleSlice = createSlice({
    name: 'csvStyle',
    initialState,
    reducers: {
        setEditingLayer: (state, { payload }: { payload: CsvLayerTreeItem | null }) => {
            state.editingLayer = payload;
        },
    },
});

export const { setEditingLayer } = csvStyleSlice.actions;

export const selectEditingLayer = (state: RootState): CsvLayerTreeItem | null => {
    return state.csvStyle.editingLayer;
};

export default csvStyleSlice.reducer;
