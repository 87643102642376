import { HighlightSetStyleProperties } from 'helpers/highlightSetHelper';
import { BuildingGeometryRef } from './BuildingGeometryRef';
import { CsvLayerMetadata } from './CsvLayerMetadata';

export interface OsmHighlightSetMetadata extends HighlightSetStyleProperties {
    csvLayerMetadata?: Omit<CsvLayerMetadata, 'styles'>;
    buildings: OsmHighlightSetBuilding[];
}

export interface OsmHighlightSetBuildingSaveStateV1 {
    id: string;
    extraId?: string;
    osmId?: number;
    name?: string;
    customName?: string;
    height?: number;
    buildingLevels?: number;
    customPosition?: { latitude: number; longitude: number };
}

export type OsmHighlightSetBuildingSaveStateV2 = OsmHighlightSetBuilding;
export type OsmHighlightSetBuildingSaveState =
    | OsmHighlightSetBuildingSaveStateV1
    | OsmHighlightSetBuildingSaveStateV2;

export function buildingMetadataFromSaveState(
    saveState: OsmHighlightSetBuildingSaveState
): OsmHighlightSetBuilding {
    if ('osmId' in saveState && saveState.osmId && !isNaN(Number(saveState.osmId))) {
        const { osmId, ...rest } = saveState;
        return {
            ...rest,
            geometryRef: { layerType: 'osm', id: Number(osmId) },
        };
    } else {
        return saveState;
    }
}

export interface OsmHighlightSetBuilding {
    id: string;
    extraId?: string;
    geometryRef?: BuildingGeometryRef;
    name?: string;
    customName?: string;
    height?: number;
    buildingLevels?: number;
    customPosition?: { latitude: number; longitude: number };
}

export interface OsmHighlightSetPropertyMetadata {
    isPreviewTable?: boolean;
    pinType?: string;
    pinColor?: string;
}

export interface OsmBuildingSaveState {
    key: string;
    title?: string;
    checked: boolean;
    metadata?: HighlightSetStyleProperties;
}
