import { useEffect } from 'react';
import Graphic from '@arcgis/core/Graphic';
import { App } from '@jll/react-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, uniq } from 'lodash';

import {
    applyEditsToLayer,
    createBuildingGraphicToEdit,
    createSketchExtrudedPolygon,
    DEFAULT_POLYGON_HEIGHT_IN_METERS,
    deleteFeaturesFromEditsLayer,
    getGraphicHeight,
    isBuildingEditsLayer,
    queryBuildingEditsLayer,
    savePropertyCore,
    setGraphicHeight,
    showAllFeatures,
} from 'helpers/polygonEditorHelper';
import {
    isMissingPolygonsPinsLayer,
    queryMissingPropertiesLayer,
    setMissingPropertiesVisibilityOnMap,
} from 'helpers/searchHelper';
import { useMap } from 'hooks/MapProvider';
import {
    saveExcludedKeys,
    selectOsmAdminExcludedKeys,
    setLayerExcludedBuildingIds,
} from 'store/buildingsExcludeSettingsSlice';
import { selectLookupByMarketSphereId } from 'store/marketSphereOsmMappingSlice';
import {
    addGraphicsToUpdate,
    removeEditedMarketSpherePropertyIds,
    selectedGraphics,
    selectEditedMarketSpherePropertyId,
    selectGraphicsToUpdate,
    selectOpenPolygonEditor,
    setEditedMarketSpherePropertyIds,
    setOpenPolygonEditor,
    setSelectedGraphics,
} from 'store/polygonsLayerSlice';
import { LayerExcludedBuildings } from 'types/BuildingsExcludeSettings';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { MarketSphereOsmMapping } from 'types/MarketSphereOsmMappings';
import isDefined from 'utils/isDefined';

export const usePolygonEditorActions = () => {
    const dispatch = useAppDispatch();
    const { message } = App.useApp();
    const { buildingEditing } = useFlags();

    const osmMapping = useAppSelector(selectLookupByMarketSphereId);

    const currentGraphics = useAppSelector(selectedGraphics);
    const graphicsToUpdate = useAppSelector(selectGraphicsToUpdate);
    const marketSpherePropertyIds = useAppSelector(selectEditedMarketSpherePropertyId);
    const osmAdminKeys = useAppSelector(selectOsmAdminExcludedKeys);

    const isPolygonEditorOpen = useAppSelector(selectOpenPolygonEditor);

    const { hideMapContextMenu } = useMap();

    useEffect(() => {
        setMissingPropertiesVisibilityOnMap(marketSpherePropertyIds);
    }, [marketSpherePropertyIds]);

    const checkIfBuildingIsEdited = (marketSpherePropertyId: number) => {
        return marketSpherePropertyIds.includes(marketSpherePropertyId);
    };

    const getMarketSpherePropertyId = () => {
        const graphic = graphicsToUpdate[0] ?? currentGraphics[0];
        if (!graphic) return undefined;
        const attributes = graphic.attributes;
        return attributes?.marketSpherePropertyId ?? attributes?.MarketSpherePropertyId;
    };

    const openPolygonEditor = async () => {
        if (currentGraphics?.length) {
            const layerId = currentGraphics[0].layer.id;
            if (isMissingPolygonsPinsLayer(layerId) || isBuildingEditsLayer(layerId)) {
                const editableGraphics: Graphic[] = [];
                currentGraphics.forEach((graphic) => {
                    const { attributes, geometry, layer } = graphic;
                    const graphicHeight = attributes.Height ?? attributes.height;
                    const height = graphicHeight ?? DEFAULT_POLYGON_HEIGHT_IN_METERS;
                    editableGraphics.push(
                        new Graphic({
                            geometry,
                            attributes: {
                                ...attributes,
                                MarketSpherePropertyId: getMarketSpherePropertyId(),
                            },
                            layer,
                            symbol: createSketchExtrudedPolygon(height),
                        })
                    );
                });
                dispatch(addGraphicsToUpdate(editableGraphics));
                dispatch(setOpenPolygonEditor(true));
            }
        }
        hideMapContextMenu();
    };

    const saveBuildingExclusion = (keys: number[]) => {
        const payload = {
            layer: 'OSM',
            buildingIds: keys,
        } as LayerExcludedBuildings;
        dispatch(setLayerExcludedBuildingIds(payload));
        dispatch(saveExcludedKeys(payload));
    };

    const onRedraw = async (osmRecord: MarketSphereOsmMapping, marketSpherePropertyId: number) => {
        const graphics = [] as Graphic[];
        if (osmRecord?.marketSpherePropertyId && !checkIfBuildingIsEdited(marketSpherePropertyId)) {
            const graphic = await createBuildingGraphicToEdit(
                osmRecord.marketSpherePropertyId,
                DEFAULT_POLYGON_HEIGHT_IN_METERS
            );
            if (graphic) {
                graphics.push(graphic);
                const keys = uniq([...osmAdminKeys, osmRecord?.osmId]);
                saveBuildingExclusion(keys);
            } else {
                message.error('Redraw building failed');
                onCancelPolygonEditor();
            }
        } else if (marketSpherePropertyId) {
            const features = await queryBuildingEditsLayer([marketSpherePropertyId]);
            if (features?.length) {
                features.forEach((feature) => {
                    const height = feature.attributes.Height ?? DEFAULT_POLYGON_HEIGHT_IN_METERS;
                    feature.symbol = createSketchExtrudedPolygon(height);
                    graphics.push(feature);
                });
            }
        }
        if (graphics.length) {
            dispatch(addGraphicsToUpdate(graphics));
            dispatch(setOpenPolygonEditor(true));
        }
    };

    const showRedrawBuildingMenuOption = (osmRecord: MarketSphereOsmMapping | undefined) => {
        const marketSpherePropertyId = getMarketSpherePropertyId();
        return (
            buildingEditing &&
            (osmRecord?.marketSpherePropertyId ?? checkIfBuildingIsEdited(marketSpherePropertyId))
        );
    };

    const showDrawBuildingMenuOption = () => {
        const marketSpherePropertyId = getMarketSpherePropertyId();
        if (!marketSpherePropertyId) return false;
        return buildingEditing && !checkIfBuildingIsEdited(marketSpherePropertyId);
    };

    const isBuildingCreatedOrInCreation = (
        osmRecord: MarketSphereOsmMapping | undefined,
        marketSpherePropertyId: string | undefined
    ) => {
        return (
            isPolygonEditorOpen ||
            isEmpty(marketSpherePropertyId) ||
            !isEmpty(osmRecord?.marketSpherePropertyId) ||
            checkIfBuildingIsEdited(Number(marketSpherePropertyId))
        );
    };

    const onCancelPolygonEditor = () => {
        const marketSpherePropertyId = getMarketSpherePropertyId();
        const osmRecord = osmMapping[marketSpherePropertyId];
        if (osmRecord && !checkIfBuildingIsEdited(marketSpherePropertyId)) {
            const keys = osmAdminKeys.filter((key) => key !== osmRecord?.osmId);
            saveBuildingExclusion(keys);
        }
        onClosePolygonEditor();
    };

    const onClosePolygonEditor = () => {
        showAllFeatures();
        dispatch(addGraphicsToUpdate([]));
        dispatch(setSelectedGraphics([]));
        dispatch(setOpenPolygonEditor(false));
    };

    const onSavePolygon = async (
        graphics: Graphic[],
        buildingStatus: string | undefined,
        propertyType: string | undefined
    ) => {
        const { attributes } = graphicsToUpdate[0];
        const { MarketSpherePropertyId: marketSpherePropertyId, BlackbirdId: blackbirdId } =
            attributes;

        if (!marketSpherePropertyId) {
            return;
        }
        graphics.forEach((graphic) => {
            const height = getGraphicHeight(graphic);
            graphic.set('attributes', {
                MarketSpherePropertyId: marketSpherePropertyId,
                BlackbirdId: blackbirdId,
                Height: height,
            });
            setGraphicHeight(graphic, height);
        });
        const output = await savePropertyCore(
            graphics,
            marketSpherePropertyId.toString(),
            blackbirdId
        );
        if (output) {
            graphics.forEach((graphic) => {
                graphic.attributes['BlackbirdId'] = Number(output);
                graphic.attributes['BuildingStatus'] = buildingStatus;
                graphic.attributes['PropertyType'] = propertyType;
            });
            await applyEditsToLayer(graphics, graphicsToUpdate);
            if (graphics.length) {
                dispatch(setEditedMarketSpherePropertyIds([marketSpherePropertyId]));
            }
            message.success('Your changes have been saved successfully.');
        }
        onClosePolygonEditor();
    };

    const enableMissingPolygonEditing = async (marketSpherePropertyId: number) => {
        const output = await queryMissingPropertiesLayer([marketSpherePropertyId], true);
        if (output?.features?.length) {
            dispatch(setSelectedGraphics(output.features));
        }
    };

    const getRedrawMenuOptionTitle = (marketSpherePropertyId: string | undefined) => {
        if (isEmpty(marketSpherePropertyId)) return '';
        return checkIfBuildingIsEdited(Number(marketSpherePropertyId))
            ? 'Edit Building Geometry'
            : 'Redraw Building';
    };

    const onDeletePolygon = async (graphicsToDelete: Graphic[]) => {
        const objectIds = graphicsToDelete
            .map((graphic) => graphic.attributes['ObjectId'] || graphic.attributes['OBJECTID'])
            .filter(isDefined) as number[];

        if (objectIds.length) {
            await deleteFeaturesFromEditsLayer(uniq(objectIds));
        }

        const marketSpherePropertyId = getMarketSpherePropertyId();
        if (!marketSpherePropertyId) return;
        const features = await queryBuildingEditsLayer([marketSpherePropertyId]);
        const osmRecord = osmMapping[marketSpherePropertyId];
        if (!features?.length) {
            if (osmRecord && checkIfBuildingIsEdited(marketSpherePropertyId)) {
                const keys = osmAdminKeys.filter((key) => key !== osmRecord?.osmId);
                saveBuildingExclusion(keys);
            }
            dispatch(removeEditedMarketSpherePropertyIds([marketSpherePropertyId]));
        }
    };

    return {
        openPolygonEditor,
        onRedraw,
        showRedrawBuildingMenuOption,
        showDrawBuildingMenuOption,
        onCancelPolygonEditor,
        onSavePolygon,
        isBuildingCreatedOrInCreation,
        checkIfBuildingIsEdited,
        enableMissingPolygonEditing,
        getRedrawMenuOptionTitle,
        onDeletePolygon,
    };
};
