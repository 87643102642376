import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Typography } from '@jll/react-ui-components';

import { savePresentation } from 'store/presentationSlice';
import { useAppDispatch } from 'types/hooks';

enum PresentationStates {
    SAVING = 'Save in progress...',
    SAVED = 'Presentation saved correctly',
    ERROR = 'Presentation failed to save, please contact blackbird@jll.com if this error continues',
}

const presentationToastMessage = (text: string, _: boolean, isError = false) => {
    // const presentationMessageStyle: CSSProperties = {
    //     marginLeft: isActionMenuVisible ? '-50px' : '120px',
    // };

    // TODO: Add a style for the message

    const content = <Typography.Text style={{ fontSize: 16 }}>{text}</Typography.Text>;
    message.open({
        type: isError ? 'error' : 'success',
        content: content,
        duration: 5,
    });
};

const usePresentationActions = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSave = useCallback(
        async function (presentationId: number, presentationName: string) {
            try {
                presentationToastMessage(PresentationStates.SAVING, false);
                const id = await dispatch(
                    savePresentation({ presentationId, presentationName })
                ).unwrap();

                // It was a new presentation or a save-as, so we will update the URL to the new id
                if (presentationId === 0) {
                    navigate(`/presentation/${id}`);
                }
                presentationToastMessage(PresentationStates.SAVED, false);
            } catch {
                presentationToastMessage(PresentationStates.ERROR, false, true);
            }
        },
        [dispatch, navigate]
    );

    return {
        savePresentation: handleSave,
    };
};

export default usePresentationActions;
