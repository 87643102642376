import { createSlice } from '@reduxjs/toolkit';

import ChatMessage from 'components/Chat/ChatMessage';
import { RootState } from 'store';

interface AssistantPanelState {
    showAssistant: boolean;
    apiKey: string;
    apiUrl: string;
    model: string;
    messages: ChatMessage[];
    loading: boolean;
}

export enum OpenAiModels {
    GPT_3_5_TURBO = 'gpt-35-turbo',
    GPT_4 = 'gpt-4-preview',
    GPT_4O = 'gpt-4o',
}

const LOCAL_STORAGE_KEYS = {
    API_KEY: 'blackbird-assistant-api-key',
    API_URL: 'blackbird-assistant-api-url',
    MODEL: 'blackbird-assistant-model',
};

const initialState: AssistantPanelState = {
    showAssistant: false,
    apiKey: localStorage.getItem(LOCAL_STORAGE_KEYS.API_KEY) || '',
    apiUrl:
        localStorage.getItem(LOCAL_STORAGE_KEYS.API_URL) ||
        'https://blackbird-sandbox-openai.openai.azure.com/',
    model: localStorage.getItem(LOCAL_STORAGE_KEYS.MODEL) || OpenAiModels.GPT_4O,
    messages: [],
    loading: false,
};

const assistantSlice = createSlice({
    name: 'assistant',
    initialState,
    reducers: {
        loadFromLocalStorage: (state) => {
            state.apiKey = localStorage.getItem(LOCAL_STORAGE_KEYS.API_KEY) || state.apiKey;
            state.apiUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.API_URL) || state.apiUrl;
            state.model = localStorage.getItem(LOCAL_STORAGE_KEYS.MODEL) || state.model;
        },
        setApiKey: (state, action: { payload: string }) => {
            state.apiKey = action.payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.API_KEY, action.payload);
        },
        setApiUrl: (state, action: { payload: string }) => {
            state.apiUrl = action.payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.API_URL, action.payload);
        },
        setModel: (state, action: { payload: string }) => {
            state.model = action.payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.MODEL, action.payload);
        },
        addMessage: (state, action: { payload: ChatMessage }) => {
            state.messages.push(action.payload);
        },
        appendMessage: (state, action: { payload: { key: string; content: string } }) => {
            const message = state.messages.find((message) => message.key === action.payload.key);
            if (message) {
                message.message += action.payload.content;
            }
        },
        clearMessages: (state) => {
            state.messages = [];
            state.loading = false;
        },
        setLoading: (state, action: { payload: boolean }) => {
            state.loading = action.payload;
        },
    },
});

export const {
    loadFromLocalStorage,
    setApiKey,
    setApiUrl,
    setModel,
    addMessage,
    clearMessages,
    setLoading,
    appendMessage,
} = assistantSlice.actions;

export const selectApiKey = (state: RootState) => state.assistant.apiKey;
export const selectApiUrl = (state: RootState) => state.assistant.apiUrl;
export const selectModel = (state: RootState) => state.assistant.model;
export const selectMessages = (state: RootState) => state.assistant.messages;
export const selectLoading = (state: RootState) => state.assistant.loading;

export default assistantSlice.reducer;
