import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Mesh from '@arcgis/core/geometry/Mesh';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import MeshMaterial from '@arcgis/core/geometry/support/MeshMaterial';
import MeshTexture from '@arcgis/core/geometry/support/MeshTexture';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import CornersGeoreference from '@arcgis/core/layers/support/CornersGeoreference';
import ImageElement from '@arcgis/core/layers/support/ImageElement';
import SceneView from '@arcgis/core/views/SceneView';

import {
    AbsoluteOverlayEditMetadata,
    GroundOverlayEditMetadata,
    ImageOverlayMetadata,
    ScreenOverlayEditMetadata,
} from '../types/Layers/ImageLayerMetadata';
import { LibraryImageSaveState } from '../types/Layers/LibraryItemSaveState';
import { ImageOverlayTreeItem } from '../types/Layers/LibraryLayerTreeItem';
import { getImage } from '../utils/cloudinaryUtils';
import { getImageOverlaySize } from '../utils/overlayUtils';

export enum ImageModelTypes {
    'ABSOLUTE' = 1,
    'CLAMPED_TO_GROUND' = 3,
    'SCREEN' = 4,
}

export interface Corners {
    bottomLeft: Point;
    topLeft: Point;
    topRight: Point;
    bottomRight: Point;
}

export interface LatLngPnt {
    latitude?: number;
    longitude?: number;
}

export const getImageProperties = async (imageId: number) => {
    const href = getImage({
        folder: 'imagelayer',
        id: imageId,
    }).toURL();
    const { resizedWidth, resizedHeight } = await getImageOverlaySize(href);
    return {
        width: resizedWidth,
        height: resizedHeight,
        href: href,
    };
};

export const createImageLayerItem = (layer: LibraryImageSaveState): ImageOverlayTreeItem => {
    const baseState = {
        key: layer.guid,
        id: layer.id,
        title: layer.name,
        itemType: 'Image',
        isLeaf: true,
        checked: layer.active,
    };
    const baseMetadata = {
        key: layer.guid,
        clampingType: layer.clampingType,
        image: {
            imageId: layer.imageLayerId,
            title: layer.name,
        },
    };
    switch (layer.clampingType) {
        case ImageModelTypes.SCREEN: {
            return {
                ...baseState,
                metadata: {
                    ...baseMetadata,
                    screenOverlayEdit: {
                        screenX: layer.screenX,
                        screenY: layer.screenY,
                        opacity: layer.opacity,
                        width: layer.width,
                        height: layer.height,
                        keepRatio: layer.aspectRatioLock,
                        rotation: layer.rotateMax,
                        fullScreen: layer.isFullScreen ?? false,
                        visible: false,
                        relativeScreenX: layer.relativeScreenX,
                        relativeScreenY: layer.relativeScreenY,
                        relativeWidth: layer.relativeWidth,
                        relativeHeight: layer.relativeHeight,
                    } as ScreenOverlayEditMetadata,
                },
            } as ImageOverlayTreeItem;
        }
        case ImageModelTypes.ABSOLUTE: {
            return {
                ...baseState,
                metadata: {
                    ...baseMetadata,
                    absoluteOverlayEdit: {
                        latitude: layer.latitude,
                        longitude: layer.longitude,
                        width: layer.width,
                        height: layer.height,
                        altitude: layer.altitude,
                        tilt: layer.tilt,
                        roll: layer.roll,
                        rotation: layer.rotateMax,
                        opacity: layer.opacity,
                        keepRatio: layer.aspectRatioLock,
                    },
                },
            } as ImageOverlayTreeItem;
        }
        case ImageModelTypes.CLAMPED_TO_GROUND: {
            return {
                ...baseState,
                metadata: {
                    ...baseMetadata,
                    groundOverlayEdit: {
                        latitude: layer.latitude,
                        longitude: layer.longitude,
                        opacity: layer.opacity,
                        width: layer.width,
                        height: layer.height,
                        keepRatio: layer.aspectRatioLock,
                        rotation: layer.rotateMax,
                    },
                },
            } as ImageOverlayTreeItem;
        }
        default:
            return {
                ...baseState,
            } as ImageOverlayTreeItem;
    }
};

export const getImageLibrarySaveState = (
    metadata: ImageOverlayMetadata
): LibraryImageSaveState | undefined => {
    const baseState = {
        itemType: 'Image',
        clampingType: metadata?.clampingType,
    } as LibraryImageSaveState;
    if (metadata) {
        const { absoluteOverlayEdit, screenOverlayEdit, groundOverlayEdit } = metadata;
        switch (metadata.clampingType) {
            case ImageModelTypes.ABSOLUTE:
                return {
                    ...baseState,
                    imageLayerId: metadata.image?.imageId,
                    latitude: absoluteOverlayEdit.latitude,
                    longitude: absoluteOverlayEdit.longitude,
                    tilt: absoluteOverlayEdit.tilt,
                    roll: absoluteOverlayEdit.roll,
                    altitude: absoluteOverlayEdit.altitude,
                    width: absoluteOverlayEdit.width,
                    height: absoluteOverlayEdit.height,
                    opacity: absoluteOverlayEdit.opacity,
                    rotateMax: absoluteOverlayEdit.rotation,
                } as LibraryImageSaveState;
            case ImageModelTypes.CLAMPED_TO_GROUND:
                return {
                    ...baseState,
                    imageLayerId: metadata.image?.imageId,
                    latitude: groundOverlayEdit.latitude,
                    longitude: groundOverlayEdit.longitude,
                    rotateMax: groundOverlayEdit.rotation,
                    width: groundOverlayEdit.width,
                    height: groundOverlayEdit.height,
                    opacity: groundOverlayEdit.opacity,
                } as LibraryImageSaveState;
            case ImageModelTypes.SCREEN:
                return {
                    ...baseState,
                    imageLayerId: metadata.image?.imageId,
                    screenX: screenOverlayEdit.screenX,
                    screenY: screenOverlayEdit.screenY,
                    rotateMax: screenOverlayEdit.rotation,
                    width: screenOverlayEdit.width,
                    height: screenOverlayEdit.height,
                    opacity: screenOverlayEdit.opacity,
                    aspectRatioLock: screenOverlayEdit.keepRatio,
                    isFullScreen: screenOverlayEdit.fullScreen,
                    relativeScreenX: screenOverlayEdit.relativeScreenX,
                    relativeScreenY: screenOverlayEdit.relativeScreenY,
                    relativeWidth: screenOverlayEdit.relativeWidth,
                    relativeHeight: screenOverlayEdit.relativeHeight,
                } as LibraryImageSaveState;
        }
    } else {
        return baseState as LibraryImageSaveState;
    }
};

export const getSquarePercentOfMap = (view: SceneView, aspectRatio: number) => {
    const fovRadian = (view.camera.fov * Math.PI) / 180;
    const distanceZ = Math.abs(view.camera.position.z);
    const halfHeight = (distanceZ * Math.tan(fovRadian / 2)) / 2;
    const halfWidth = halfHeight * aspectRatio;
    return {
        desiredWidth: Math.abs(halfWidth),
        desiredHeight: Math.abs(halfHeight),
    };
};

export const toDataUrl = (url: string) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
            reader.result as string;
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

export const createAbsoluteImagePlane = async (
    imageUri: string,
    settings: AbsoluteOverlayEditMetadata,
    rotate?: number
) => {
    const { latitude, longitude, width, height, tilt, roll, altitude } = settings;
    if (latitude && longitude && width && height && altitude !== undefined) {
        await toDataUrl(imageUri);
        const centerPoint = new Point({
            longitude: longitude,
            latitude: latitude,
            z: altitude + height / 2,
        });
        const webMercatorCenterPoint = webMercatorUtils.geographicToWebMercator(
            centerPoint
        ) as Point;

        const _cubeTexture = new MeshTexture({
            url: imageUri,
            transparent: true,
            wrap: {
                vertical: 'clamp',
                horizontal: 'clamp',
            },
        });
        const _cubeMaterial = new MeshMaterial({
            colorTexture: _cubeTexture,
        });
        const plane = Mesh.createPlane(webMercatorCenterPoint, {
            size: {
                height: height,
                width: width,
            },
            material: _cubeMaterial,
            geographic: false,
        });
        const rotatedPlane = plane.rotate(0, 0, 180); // initial reversed
        return rotatedPlane.rotate(tilt || 0, roll || 0, rotate || 0);
    }
};

export const getMeshBoundary = (mesh: Mesh): Corners => {
    const positions = mesh.vertexAttributes.position;

    const bottomRight = new Point({
        x: positions[0],
        y: positions[1],
        z: positions[2],
        spatialReference: mesh.spatialReference,
    });
    const bottomLeft = new Point({
        x: positions[3],
        y: positions[4],
        z: positions[5],
        spatialReference: mesh.spatialReference,
    });
    const topLeft = new Point({
        x: positions[6],
        y: positions[7],
        z: positions[8],
        spatialReference: mesh.spatialReference,
    });
    const topRight = new Point({
        x: positions[9],
        y: positions[10],
        z: positions[11],
        spatialReference: mesh.spatialReference,
    });
    return {
        topLeft: topLeft,
        topRight: topRight,
        bottomLeft: bottomLeft,
        bottomRight: bottomRight,
    };
};

export const getCorners = (polygon: Polygon): Corners => {
    return {
        topLeft: polygon.getPoint(0, 0),
        topRight: polygon.getPoint(0, 1),
        bottomRight: polygon.getPoint(0, 2),
        bottomLeft: polygon.getPoint(0, 3),
    };
};

export const getSizes = (polygon: Polygon) => {
    const corners = getCorners(polygon);
    const width = Math.floor(geometryEngine.distance(corners.bottomLeft, corners.bottomRight));
    const height = Math.floor(geometryEngine.distance(corners.bottomLeft, corners.topLeft));
    const depth = corners.topLeft.z - corners.bottomLeft.z;

    return {
        width: width,
        height: height,
        depth: depth,
    };
};

export const createGroundRectanglePolygon = (settings: GroundOverlayEditMetadata) => {
    const { latitude, longitude, width, height, rotation } = settings;
    if (latitude && longitude && width && height) {
        const { bottomLeft, topLeft, bottomRight, topRight } = createCorners(
            latitude,
            longitude,
            width,
            height
        );
        const polygon = new Polygon({
            rings: [
                [
                    [topLeft.x, topLeft.y],
                    [topRight.x, topRight.y],
                    [bottomRight.x, bottomRight.y],
                    [bottomLeft.x, bottomLeft.y],
                    [topLeft.x, topLeft.y],
                ],
            ],
            spatialReference: topLeft.spatialReference,
        });
        const centroid = polygon.centroid;
        const rotatedPolygon = geometryEngine.rotate(polygon, rotation);
        const rotatedCorners = {
            topLeft: geometryEngine.rotate(topLeft, rotation, centroid) as Point,
            topRight: geometryEngine.rotate(topRight, rotation, centroid) as Point,
            bottomRight: geometryEngine.rotate(bottomRight, rotation, centroid) as Point,
            bottomLeft: geometryEngine.rotate(bottomLeft, rotation, centroid) as Point,
        };
        return {
            corners: rotatedCorners,
            polygon: rotatedPolygon,
        };
    }
};

export const createImageGeoreference = (corner: Corners) => {
    return new CornersGeoreference({
        bottomLeft: corner.bottomLeft,
        topLeft: corner.topLeft,
        bottomRight: corner.bottomRight,
        topRight: corner.topRight,
    });
};

export const createGroundRectangleImage = (corner: Corners, imageUri: string) => {
    const imageGeoreference = createImageGeoreference(corner);
    return new ImageElement({
        image: imageUri,
        georeference: imageGeoreference,
    });
};

export const createCorners = (
    latitude: number,
    longitude: number,
    width: number,
    height: number
): Corners => {
    const centerPoint = new Point({
        latitude: latitude,
        longitude: longitude,
    });
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const webMercatorCenterPoint = webMercatorUtils.geographicToWebMercator(centerPoint) as Point;
    const topLeft = new Point({
        x: webMercatorCenterPoint.x - halfWidth,
        y: webMercatorCenterPoint.y + halfHeight,
        spatialReference: webMercatorCenterPoint.spatialReference,
    });
    const topRight = new Point({
        x: webMercatorCenterPoint.x + halfWidth,
        y: webMercatorCenterPoint.y + halfHeight,
        spatialReference: webMercatorCenterPoint.spatialReference,
    });
    const bottomRight = new Point({
        x: webMercatorCenterPoint.x + halfWidth,
        y: webMercatorCenterPoint.y - halfHeight,
        spatialReference: webMercatorCenterPoint.spatialReference,
    });
    const bottomLeft = new Point({
        x: webMercatorCenterPoint.x - halfWidth,
        y: webMercatorCenterPoint.y - halfHeight,
        spatialReference: webMercatorCenterPoint.spatialReference,
    });
    return {
        bottomLeft: bottomLeft,
        topLeft: topLeft,
        topRight: topRight,
        bottomRight: bottomRight,
    };
};

export const validateOverlayMetadata = (imageOverlayEdit: ImageOverlayMetadata) => {
    const clampingType = imageOverlayEdit.clampingType;
    if (clampingType == ImageModelTypes.ABSOLUTE) {
        const absoluteEdit = imageOverlayEdit.absoluteOverlayEdit;
        return absoluteEdit.latitude !== undefined && absoluteEdit.longitude !== undefined;
    }
    if (clampingType == ImageModelTypes.CLAMPED_TO_GROUND) {
        const groundEdit = imageOverlayEdit.groundOverlayEdit;
        return groundEdit.latitude !== undefined && groundEdit.longitude !== undefined;
    }
    return true;
};

type ImageSize = {
    width: number;
    height: number;
};

type ImagePositionInCanvas = ImageSize & {
    left: number;
    top: number;
};

export const fitAndCenterImageInCanvas = (
    imageSize: ImageSize,
    canvasSize: ImageSize
): ImagePositionInCanvas => {
    // Calculate the scale factors for width and height
    const widthScale = canvasSize.width / (imageSize.width || 1);
    const heightScale = canvasSize.height / (imageSize.height || 1);

    //Select the smaller scale factor to ensure the image fits in the canvas
    const scale = Math.min(widthScale, heightScale);

    // Calculate the dimensions of the image when scaled
    const fittedWidth = imageSize.width * scale;
    const fittedHeight = imageSize.height * scale;

    // Calculate the x and y coordinates of the image in the canvas
    const left = (canvasSize.width - fittedWidth) / 2;
    const top = (canvasSize.height - fittedHeight) / 2;

    return {
        width: fittedWidth,
        height: fittedHeight,
        left,
        top,
    };
};

export const getOverlaySetBounds = (libraryItem: ImageOverlayTreeItem) => {
    const metadata = libraryItem.metadata;
    switch (metadata.clampingType) {
        case ImageModelTypes.ABSOLUTE:
            return {
                target: new Point({
                    latitude: metadata.absoluteOverlayEdit.latitude,
                    longitude: metadata.absoluteOverlayEdit.longitude,
                }),
                zoom: 17,
            };
        case ImageModelTypes.CLAMPED_TO_GROUND:
            return {
                target: new Point({
                    latitude: metadata.groundOverlayEdit.latitude,
                    longitude: metadata.groundOverlayEdit.longitude,
                }),
                zoom: 17,
            };
    }
};
