import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import MediaLayer from '@arcgis/core/layers/MediaLayer';
import ExtentAndRotationGeoreference from '@arcgis/core/layers/support/ExtentAndRotationGeoreference';
import ImageElement from '@arcgis/core/layers/support/ImageElement';
import { get, isEmpty } from 'lodash';

import { FetchLayerResult } from 'api/kmlApi';
import { LibraryMediaLayerSaveState } from 'types/Layers/LibraryItemSaveState';
import LibraryLayerTreeItem, { MediaLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import {
    MediaLayerMetadata,
    SitePlanMetadata,
    SitePlanSaveState,
} from 'types/Layers/MediaLayerMetadata';
import endpoints from 'utils/apiClient/endpoints';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import { createExtentFromKmlBounds } from 'utils/esriUtils';
import { nanoid } from 'utils/idUtils';

interface KmlLayerItem {
    id: number;
    name: string;
}

export interface KmlSitePlan {
    layerId: number;
    layerName: string;
    sitePlanName: string;
    coordinates: string;
    styles: string;
}

export interface SitePlansSearchPayload {
    searchQuery: string;
    offset: number;
    limit: number;
    presentationId: number;
}

export const fetchSitePlansLayers = async (
    payload: SitePlansSearchPayload
): Promise<FetchLayerResult> => {
    const response = await endpoints.kml.sitePlansLayers.get({
        queryParameters: { ...payload },
    });
    return await response.json();
};

export const fetchSitePlansByLayerId = async (layerId: number): Promise<KmlSitePlan[]> => {
    const response = await endpoints.kml.sitePlansByLayerId.get({
        templateValues: { layerId },
    });
    return await response.json();
};

export const createMediaLayerChildren = (parentKey: string, sitePlans: KmlSitePlan[]) => {
    return sitePlans.map((sitePlan) => ({
        id: 0,
        key: `${parentKey}--${nanoid()}`,
        title: sitePlan.sitePlanName,
        checked: true,
        itemType: 'MediaLayer',
        isLeaf: true,
        ownerId: parentKey,
        metadata: {
            coordinates: sitePlan.coordinates,
            style: sitePlan.styles,
        } as SitePlanMetadata,
    })) as MediaLayerTreeItem[];
};

export const createMediaLayerTreeItem = (layer: KmlLayerItem | LibraryMediaLayerSaveState) => {
    const itemType = 'MediaLayer';
    const { id, name } = layer as KmlLayerItem;
    const { guid, children, kmlLayerId } = layer as LibraryMediaLayerSaveState;
    const key = guid ?? `${itemType}--${nanoid()}`;
    const nodes = (children ?? []) as MediaLayerTreeItem[];
    const transformedChildren = nodes.map(
        (child: MediaLayerTreeItem) =>
            ({
                ...child,
                id: 0,
                itemType,
                isLeaf: true,
                ownerId: key,
                metadata: child.metadata,
            } as MediaLayerTreeItem)
    );
    let isActive = true;
    if (transformedChildren.length > 0 && !transformedChildren.some((child) => child.checked)) {
        isActive = false;
    }
    return {
        id: 0,
        itemType,
        key,
        title: name,
        children: transformedChildren,
        checked: isActive,
        metadata: {
            kmlLayerId: kmlLayerId ?? id,
        },
    } as MediaLayerTreeItem;
};

export const createMediaLayerSaveState = (layer: MediaLayerTreeItem) => {
    const { metadata, children } = layer;
    const { kmlLayerId } = metadata as MediaLayerMetadata;
    const items = [] as SitePlanSaveState[];
    children?.forEach((item) => {
        const { key, title, checked, metadata } = item as MediaLayerTreeItem;
        items.push({
            key,
            title,
            checked,
            metadata,
        } as SitePlanSaveState);
    });
    return {
        children: items,
        kmlLayerId,
    };
};

export const createMediaLayerFromSavedState = (
    layer: LibraryMediaLayerSaveState
): MediaLayerTreeItem => {
    const itemType = 'MediaLayer';
    const { guid, name, children, kmlLayerId } = layer;
    const key = guid ?? `${itemType}--${nanoid()}`;
    const nodes = children && !isEmpty(children) ? JSON.parse(children) : [];
    const transformedChildren = nodes.map(
        (child: MediaLayerTreeItem) =>
            ({
                id: 0,
                key: child.key,
                title: child.title,
                checked: child.checked,
                itemType,
                isLeaf: true,
                ownerId: key,
                metadata: child.metadata,
            } as MediaLayerTreeItem)
    );
    return {
        id: 0,
        itemType,
        key,
        title: name,
        children: transformedChildren,
        checked: layer.active,
        metadata: {
            kmlLayerId,
        },
    };
};

export const createImageElement = (child: MediaLayerTreeItem) => {
    const childMetadata = child.metadata as SitePlanMetadata;
    const coordinates = JSON.parse(childMetadata.coordinates).coordinates;
    const imageStyle = JSON.parse(childMetadata.style);

    const extent = createExtentFromKmlBounds(coordinates);
    if (!extent) return;

    const projectedExtent = webMercatorUtils.geographicToWebMercator(extent);
    const imageElement = new ImageElement({
        image: imageStyle.image.href,
        georeference: new ExtentAndRotationGeoreference({
            extent: projectedExtent,
            rotation: -coordinates.rotation,
        }),
    });
    imageElement.set('key', child.key);
    return imageElement;
};

const createMediaLayerElements = (children: MediaLayerTreeItem[]) => {
    const imageElements: ImageElement[] = [];
    for (const child of children) {
        const imageElement = createImageElement(child);
        if (imageElement) imageElements.push(imageElement);
    }
    return imageElements;
};

export const createMediaLayer = (key: string, children: MediaLayerTreeItem[]) => {
    if (!children?.length) return;
    const imageElements = createMediaLayerElements(children);
    if (imageElements?.length === 0) return;

    const mediaLayer = new MediaLayer({
        id: key,
        source: imageElements,
    });

    return mediaLayer;
};

export const getMediaLayerBounds = async (libraryItem: MediaLayerTreeItem) => {
    const layerId = libraryItem.ownerId ?? libraryItem.key;
    const mediaLayer = findMapLayer(layerId) as MediaLayer;

    if (!mediaLayer) {
        throw new Error('Media layer not found.');
    }

    if (libraryItem.ownerId) {
        const imageElements = mediaLayer.source as __esri.LocalMediaElementSource;
        const element = imageElements.elements.find(
            (element) => get(element, 'key') === libraryItem.key
        );
        if (element) {
            return (element.georeference as ExtentAndRotationGeoreference).extent;
        }
    } else {
        return mediaLayer.fullExtent;
    }
};

export const searchMediaLayerById = (libraryItems: LibraryLayerTreeItem[], layerId: string) => {
    return (
        libraryItems.find(
            (item) =>
                item.itemType === 'MediaLayer' &&
                (item.metadata as MediaLayerMetadata)?.kmlLayerId === Number(layerId)
        ) !== undefined
    );
};
