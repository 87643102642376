import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
    NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import config from 'utils/config';
import {
    AVAILABILITY_CLIENT,
    INQUIRY_CLIENT,
    LEASE_CLIENT,
    MARKETSPHERE_CLIENT,
    PROPERTY_CLIENT,
    SALES_CLIENT,
} from 'utils/gqlUtils';

const getGqlUrl = () => {
    return config.commonServicesPlatformUrl;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const authLink = setContext((_, { headers }: any) => {
    const token = config.accessToken;

    return {
        headers: {
            ...headers,
            ['Authorization']: token ? `Bearer ${token}` : '',
            ['subscription-key']: config.commonServicesPlatformSubscriptionKey,
        },
    };
});

export const startApolloClient = (uri: string): ApolloClient<NormalizedCacheObject> => {
    const propertyServiceEndpoint = createHttpLink({
        uri: `${uri}/property-service/graphql`,
        fetch: (uri, options) => {
            const body = options?.body?.toString() || '';
            return fetch(uri, {
                ...options,
                body: body.replace(/__typename\\n/gim, ''),
            });
        },
    });

    const availabilityServiceEndpoint = createHttpLink({
        uri: `${uri}/availability-service/graphql`,
        fetch: (uri, options) => {
            const body = options?.body?.toString() || '';
            return fetch(uri, {
                ...options,
                body: body.replace(/__typename\\n/gim, ''),
            });
        },
    });

    const leaseServiceEndpoint = createHttpLink({
        uri: `${uri}/lease-service/graphql`,
        fetch: (uri, options) => {
            const body = options?.body?.toString() || '';
            return fetch(uri, {
                ...options,
                body: body.replace(/__typename\\n/gim, ''),
            });
        },
    });

    const inquiryServiceEndpoint = createHttpLink({
        uri: `${uri}/inquiry/graphql`,
    });

    const salesServiceEndpoint = createHttpLink({
        uri: `${uri}/sales-service/graphql`,
        fetch: (uri, options) => {
            const body = options?.body?.toString() || '';
            return fetch(uri, {
                ...options,
                body: body.replace(/__typename\\n/gim, ''),
            });
        },
    });

    const marketSphereServiceEndpoint = createHttpLink({
        uri: `${config.marketSphereApiUrl}/graphql`,
        headers: {
            ['Subscription-Key']: config.marketSphereSubscriptionKey,
        },
    });

    const customLink = new ApolloLink((operation, forward) => {
        const clientName = operation.getContext().clientName;

        switch (clientName) {
            case PROPERTY_CLIENT:
                return authLink.concat(propertyServiceEndpoint).request(operation, forward);
            case AVAILABILITY_CLIENT:
                return authLink.concat(availabilityServiceEndpoint).request(operation, forward);
            case LEASE_CLIENT:
                return authLink.concat(leaseServiceEndpoint).request(operation, forward);
            case INQUIRY_CLIENT:
                return authLink.concat(inquiryServiceEndpoint).request(operation, forward);
            case MARKETSPHERE_CLIENT:
                return authLink.concat(marketSphereServiceEndpoint).request(operation, forward);
            case SALES_CLIENT:
                return authLink.concat(salesServiceEndpoint).request(operation, forward);
            default:
                throw new Error(`Unknown clientName: ${clientName}`);
        }
    });

    return new ApolloClient({
        link: customLink,
        cache: new InMemoryCache(),
    });
};

export default startApolloClient(getGqlUrl());
