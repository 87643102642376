import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq, uniqBy } from 'lodash';

import { searchOsmMappingsByOsmIdArray } from 'helpers/searchHelper';
import { RootState } from 'store';
import type { LoadStatus } from 'types/LoadStatus';
import { MarketSphereOsmMapping } from 'types/MarketSphereOsmMappings';
import endpoints from 'utils/apiClient/endpoints';

export interface OsmMatchingProgressState {
    state: 'matching' | 'reviewing';
    osmId: number | null;
}

interface MarketSphereOsmMappingSliceState {
    mapping: MarketSphereOsmMapping[];
    devPipelineMarketSphereIds: number[];
    matching: OsmMatchingProgressState | null;
    underRenovationOsmIds?: number[];
    status: LoadStatus;
}

const initialState: MarketSphereOsmMappingSliceState = {
    mapping: [],
    devPipelineMarketSphereIds: [],
    matching: null,
    status: 'idle',
};

export const saveMapping = createAsyncThunk(
    'marketSphereOsmMappings/saveMapping',
    async (mapping: MarketSphereOsmMapping) => {
        const response = await endpoints.marketSphereOsmMapping.save.post({
            fetchOptions: {
                body: JSON.stringify(mapping),
            },
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return mapping;
    }
);

export const loadOsmMappingForOsmIds = createAsyncThunk(
    'marketSphereOsmMappings/loadOsmMappingForOsmIds',
    async (osmIds: number[]) => {
        return await searchOsmMappingsByOsmIdArray(osmIds);
    }
);

export const MarketSphereOsmMappingSlice = createSlice({
    name: 'marketSphereOsmMappings',
    initialState,
    reducers: {
        setMarketSphereOsmMapping(state, action: PayloadAction<MarketSphereOsmMapping[]>) {
            const values = action.payload ?? [];
            state.mapping = uniqBy(
                [...state.mapping, ...values],
                (item) => `${item.marketSpherePropertyId}-${item.osmId}`
            );
        },
        setMatching(state, action: PayloadAction<OsmMatchingProgressState | null>) {
            state.matching = action.payload;
        },
        setUnderRenovationOsmIds(state, action: PayloadAction<number[]>) {
            state.underRenovationOsmIds = action.payload;
        },
        setDevPipelineMarketSphereIds(state, action: PayloadAction<number[]>) {
            state.devPipelineMarketSphereIds = uniq([
                ...state.devPipelineMarketSphereIds,
                ...action.payload,
            ]);
        },
        reduceToSpecificMappings(state, action: PayloadAction<number[]>) {
            const osmIds = action.payload;
            state.mapping = state.mapping.filter((item) => osmIds.includes(item.osmId));
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveMapping.fulfilled, (state, action) => {
            const mapping = action.payload;
            const active = mapping.active;
            const index = state.mapping.findIndex(
                (item) =>
                    item.osmId == mapping.osmId &&
                    item.marketSpherePropertyId == mapping.marketSpherePropertyId
            );
            if (index !== -1) {
                if (active) state.mapping[index] = mapping;
                else state.mapping.splice(index, 1);
            } else if (active) {
                state.mapping.push(mapping);
            }
        });
        builder.addCase(loadOsmMappingForOsmIds.fulfilled, (state, action) => {
            state.mapping = uniqBy(
                [...state.mapping, ...action.payload],
                (item) => `${item.marketSpherePropertyId}-${item.osmId}`
            );
        });
    },
});

export const selectMarketSphereOsmMapping = (state: RootState): MarketSphereOsmMapping[] => {
    return state.marketSphereOsmMapping.mapping;
};

export const selectDevPipelineMarketSpherePropertyIds = (state: RootState): number[] => {
    return state.marketSphereOsmMapping.devPipelineMarketSphereIds;
};

export const selectAllMarketSphereIdsFromMappings = createSelector(
    [selectMarketSphereOsmMapping],
    (mappings) => {
        return mappings?.map((m) => m.marketSpherePropertyId)?.filter((id) => id !== null) || [];
    }
);

export const selectDevPipelineMSIDsLookup = createSelector(
    [selectDevPipelineMarketSpherePropertyIds],
    (ids) =>
        ids.reduce((acc, id) => {
            acc[id] = true;
            return acc;
        }, {} as Record<string, boolean>)
);

export const selectLookupByMarketSphereId = createSelector(
    [selectMarketSphereOsmMapping],
    (mappings) =>
        mappings.reduce((acc, obj) => {
            acc[obj.marketSpherePropertyId] = obj;
            return acc;
        }, {} as Record<string, MarketSphereOsmMapping>)
);

export const selectMappingLookupByOsmId = createSelector(
    [selectMarketSphereOsmMapping],
    (mappings) => {
        return mappings.reduce((acc, obj) => {
            acc[obj.osmId] = obj;
            return acc;
        }, {} as Record<string, MarketSphereOsmMapping>);
    }
);

export const selectMatching = (state: RootState): OsmMatchingProgressState | null => {
    return state.marketSphereOsmMapping.matching;
};

export const {
    setMarketSphereOsmMapping,
    setMatching,
    setDevPipelineMarketSphereIds,
    setUnderRenovationOsmIds,
    reduceToSpecificMappings,
} = MarketSphereOsmMappingSlice.actions;

export default MarketSphereOsmMappingSlice.reducer;
